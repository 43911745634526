<div class="container-text"> 
    <div class="row cover-text" *ngIf="isMobile()">
        <div *ngIf="isNotMobileMenu()" class="col-sm-3" style="position:relative;" >  
            <app-sidemenu></app-sidemenu>
        </div>
        <div class="vertical-class-text imagenstyle" [ngClass]="{'col-sm-6':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div class="youtube-video-container" *ngIf="mainCoverPostViewModel.URLVideo && !mainCoverPostViewModel.URLImage">
                <iframe *ngIf="configurationViewModel.Value == 'YouTube' && mainCoverPostViewModel.YTCensored==false" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                <video *ngIf="configurationViewModel.Value == 'MediaFire' && mainCoverPostViewModel.YTCensored==false" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                <video *ngIf="mainCoverPostViewModel.YTCensored" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
            </div>
            <div [ngClass]="{'youtube-video-container':isNotMobileMenu(),'mobileimagen':!isNotMobileMenu()}"  *ngIf="mainCoverPostViewModel.URLImage">
                <img class="image" [src]='mainCoverPostViewModel.URLImage'>
            </div>
        </div>
        <div class="vertical-class-text" style="padding-top: 15px; padding-left: 15px; padding-right: 15px;" [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <p class="vertical-line-red fira-sans-strong-cover main-cover-margin-title" style="line-height: 1.25;"> {{ mainCoverPostViewModel.Title }}</p>
            <div class="row align-link" *ngIf="mainCoverPostViewModel.URLImage">
                <a class="fira-sans-link vertical-class left" href="/post/{{mainCoverPostViewModel.IdPost}}">Seguir leyendo</a>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!isMobile()">
        <div class="">
            <img class="dailyversecss" [src]='ImageDailyVerse()'>
        </div>
        <div class="vertical-class-text imagenstyle" [ngClass]="{'col-sm-6':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div class="containerIframe" *ngIf="mainCoverPostViewModel.URLVideo && !mainCoverPostViewModel.URLImage">    
                <iframe class="responsive-iframe corner-wrapper" *ngIf="configurationViewModel.Value == 'YouTube' && mainCoverPostViewModel.YTCensored==false" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe>
                <video class="dailyversecss responsive-iframe" *ngIf="configurationViewModel.Value == 'MediaFire' && mainCoverPostViewModel.YTCensored==false" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                <video class="dailyversecss responsive-iframe" *ngIf="mainCoverPostViewModel.YTCensored" width="838" height="470" [src]='mainCoverPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
            </div>
            <div  *ngIf="mainCoverPostViewModel.URLImage">
                <img class="coverimagen" [src]='mainCoverPostViewModel.URLImage'>
            </div>
        </div>
        <div class="vertical-class-text" style="padding-top: 15px; padding-left: 15px; padding-right: 15px;" [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <span class="vertical-line-red" style="padding: 0px 0px 0px 15px;">
                <p class="fira-sans-strong-cover" style="line-height: 1.25;"> {{ mainCoverPostViewModel.Title }}
                </p>
                <p class="fira-sans-cover" style="line-height: 1.25;">
                    {{ mainCoverPostViewModel.Review }}
                </p>
            </span>
            <div class="row align-link" *ngIf="mainCoverPostViewModel.URLImage">
                <a class="fira-sans-link vertical-class left" href="/post/{{mainCoverPostViewModel.IdPost}}">Seguir leyendo</a>
            </div>
        </div>
    </div>
</div>
<div>
    <app-main-profecy></app-main-profecy>
</div>
<div>
    <app-main-review></app-main-review>
</div>
<div>
    <app-main-new-focus></app-main-new-focus>
</div>

<div>
    <app-main-shorts></app-main-shorts>
</div>

<div>
    <app-bible-study></app-bible-study>
</div>

<script>
    var carrusel= document.querySelector('carrusel-container');
</script>