import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../shared/api.service';
declare const $: any;

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.css']
})
export class FavoriteListComponent implements OnInit {
  showVideoLayout : boolean = false;
  isSuscriberAuthenticated : boolean = false;

  constructor(private api: ApiService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }

  isDesck() {
    if ($(window).width() > 991 && $(window).width() < 1400) {
        return true;
    }
    return false;
  }

  ShowPosts() {
    document.getElementById("Posts").style.display = "block";;
    document.getElementById("Studies").style.display = "none";
  }
  ShowStudies() {
    document.getElementById("Posts").style.display = "none";;
    document.getElementById("Studies").style.display = "block";
  }
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    
    return false;
  };
}
