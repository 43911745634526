<div class="contact-us">
    <div class="row contact-us-header fira-sans">
        <h1>Contáctenos</h1>
    </div>
    <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-7" id="Animacion">
            <div class="vertical-line-red-item" style="height: 25px; margin-left: 23px;"></div>           
            <div *ngFor ="let item of dataViewModel; index as i;">
                <div [attr.id]="'step' + i" >
                    <div *ngIf="showItem(i)" class="vertical-line-red">
                        <div class="vertical-line-red-item">
                        </div>
                        <div class="card-body contact-us-item">                        
                            <div [innerHTML]="item.BelieveQuestion">
                            </div>
                        </div>
                      </div>
                </div> 
            </div>
            <div *ngIf="!showContact" class="row">
                <div class="col-sm-3 vertical-line-red-next">
                    <div class="vertical-line-red-item-next"></div>
                </div>
                <div class="col-sm-8 contact-us-next">
                    <button type="button" class="btn btn-danger mb-2" (click)="nextItem()" >
                        <label class="fira-sans">Siguiente</label>
                    </button>
                </div>

            </div>
            
            <div *ngIf="showContact" class="main-contact">
                <div class="vertical-line-red">

                </div>
                    <div class="row">
                        <div class="col-sm-2">
                        </div>
                        <div class="col-sm-9" style="padding-left: 10px; padding-right: 10px;">
                            <form [formGroup]="formContact" autocomplete="off">
            
                                <div class="form-group contact-items">
                                    <input type="text" class="form-control" formControlName="Name" id="Name" aria-describedby="emailHelp" placeholder="Nombre Completo">
                                </div>
                                <div class="form-group contact-items">
                                    <input type="email" class="form-control" formControlName="Email" id="Email" aria-describedby="emailHelp" placeholder="Correo Electronico">
                                </div>
                                <div class="form-group contact-items">
                                    <div class="form-floating mb-3 mt-3">
                                        <select class="form-control" id="CountryRegister" formControlName="Country" required   [(ngModel)]="activeCountry" (ngModelChange)="getStates(activeCountry?.IdCountry)">
                                            <option *ngFor="let item of CountryViewModel" [ngValue]="item">
                                                {{item.DisplayName}}
                                            </option>
                                        </select>
                                        <label for="CountryRegister" class="form-label">Seleccione un pais:</label>
                                    </div>
                                </div>
                                <div class="form-group contact-items">
                                    <div class="form-floating mb-3 mt-3">
                                        <select class="form-control" id="StateRegister" formControlName="IdState" required placeholder="Estado">
                                            <option *ngFor="let item of EstateCountry" [ngValue]="item.IdState">
                                                {{item.StateName}}
                                            </option>
                                        </select>
                                        <label for="StateRegister" class="form-label">Seleccione un estado, departamento o región:</label>
                                    </div>
                                </div>
                                <div class="form-group contact-items">
                                    <textarea class="form-control" formControlName="Message" id="Message" rows="6" placeholder="Preguntas o comentarios"></textarea>
                                </div>
                                <app-privacy-notice [eeaCountry]="this.formContact.value.Country?.EEA"></app-privacy-notice>
                                <div class="contact-button contact-items">
                                    <button type="submit" class="btn btn-danger mb-2 contact-button-style" (click)="contact()">Enviar</button>
                                </div>
                            </form>
                        </div>
                    </div>
            </div>
        </div>
        <div class="col-sm-2"></div>
    </div>
</div>


