import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BibleStudyViewModel} from '../models/bible-study-view-model';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../shared/api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';
import { TagViewModel} from '../models/tag-view-model';
declare const $: any;
declare var bootstrap: any;
@Component({
  selector: 'app-bible-study-read',
  templateUrl: './bible-study-read.component.html',
  styleUrls: ['./bible-study-read.component.css']
})
export class BibleStudyReadComponent implements OnInit {
  bibleStudyViewModel: BibleStudyViewModel = new BibleStudyViewModel();
  fullLoad:boolean=false;
  isSuscriberAuthenticated : boolean = false;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  tagViewModel: TagViewModel[];
  constructor(private apiService: ApiService, private activateroute:ActivatedRoute,private router : Router,private _sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) { }
  SliderContainerWidth: number = 200;
  numerOfRecords: number = 1;
  showCarrusel: boolean = false;

  ngAfterViewInit() {
    this.SliderContainerWidth = (this.numerOfRecords*42);
    this.cdRef.detectChanges(); 
  }

  ngOnInit(): void {
    this.bibleStudyViewModel.IdBibleStudie = this.activateroute.snapshot.params.IdBiblestudies ? Number(this.activateroute.snapshot.params.IdBiblestudies) : 0;
    this.isSuscriberAuthenticated = this.apiService.isAuthenticated();
    this.getVideoValue();

    $(() => {
              var autenticad=this.apiService.isAuthenticated();
              if(autenticad == false)
              {
                $('#myModal').modal('toggle')
              }
            });
    if(this.isSuscriberAuthenticated==true)
    {
      this.cargarpost();
      this.getTags();
    }

  }


  getTags()
  {
    this.apiService.get("/Tag/GetTags").subscribe((response: any) => {
      this.tagViewModel = response
      this.showCarrusel = true;
      this.numerOfRecords = response.length;
      this.ngAfterViewInit()
      }
    )
  }
  cargarpost()
  {
    if(this.bibleStudyViewModel.IdBibleStudie >0)
    {
      if(this.isSuscriberAuthenticated)
      {
      this.apiService.getWithAuthentication("/api/biblestudybyid/false/"+this.bibleStudyViewModel.IdBibleStudie).subscribe(
        (response: any) => {
          this.fullLoad=true;
          this.bibleStudyViewModel = response;
          this.bibleStudyViewModel.URLSaTest=this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.Test);

          if(this.bibleStudyViewModel.YTCensored == false)
          {
            this.bibleStudyViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.bibleStudyViewModel.UrlVideo:this.bibleStudyViewModel.UrlVideoBk);
          }
          else if (this.bibleStudyViewModel.YTCensored == true)
          {
            this.bibleStudyViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.UrlVideo);
          }         
        }
      );
      }
      else
      {
        this.apiService.get("/api/biblestudybyid/false/"+this.bibleStudyViewModel.IdBibleStudie).subscribe(
          (response: any) => {
            this.fullLoad=true;
            this.bibleStudyViewModel = response;
            this.bibleStudyViewModel.URLSaTest=this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.Test);
            if(this.bibleStudyViewModel.YTCensored == false)
            {
              this.bibleStudyViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.bibleStudyViewModel.UrlVideo:this.bibleStudyViewModel.UrlVideoBk);
            }
            else if (this.bibleStudyViewModel.YTCensored == true)
            {
              this.bibleStudyViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.UrlVideo);
            }
          }
        );
        }
    }
  }
  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.Image);
  }
  getMainVideo()
  {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.bibleStudyViewModel.UrlVideo);
  }
  
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};

getVideoValue()
{
  this.apiService.get("/Configuration?key=Video").subscribe((response: any) => {
    this.configurationViewModel = response
    }
  )
}
AddDeleteFavoritePost(IdPost:number)
{
  var autenticad=this.apiService.isAuthenticated();
  if(autenticad == false)
  {
    $('#myModal').modal('toggle')
  }
  else
  {
    this.apiService.postWithAuthentication("/api/favoriteStudies/"+IdPost,IdPost).subscribe(
      (response) => {
        this.cargarpost();
      },
      (error) => {
        console.log(error);
      });
    }
}
AddDeleteReadLaterPost(IdPost:number)
{
  var autenticad=this.apiService.isAuthenticated();
  if(autenticad == false)
  {
    $('#myModal').modal('toggle')
  }
  else
  {
    this.apiService.postWithAuthentication("/api/readlaterstudy/"+IdPost,IdPost).subscribe(
      (response) => {
        this.cargarpost();
      },
      (error) => {
        console.log(error);
      });
  }
}
doFilter(){
  document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth-80;
}
doLeft(){
  document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth+80;
}
}
