import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../shared/api.service';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordViewModel } from '../models/reset-password-view-model.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  showMsg : boolean = false;
  showError: boolean = false;
  form: UntypedFormGroup;
  msg : string ="";
  password : string ="";
  constructor(private api: ApiService, private fb: UntypedFormBuilder, private route: ActivatedRoute) { this.form = this.fb.group({
    Password: ['', [Validators.required, Validators.minLength(6)]],
  });}

  ngOnInit(): void {
  }

  resetPassword()
  {
    if (this.form.invalid) {
      return;
    }

    var resetPasswordViewModel = this.form.value as ResetPasswordViewModel;
    resetPasswordViewModel.Token = this.route.snapshot.queryParamMap.get('token');

    this.api.post("/api/resetPassword", resetPasswordViewModel).subscribe(
      (response) => {
        this.msg ="Su contraseña ha sido restablecida.";
        if(this.msg.length > 1)
        {
          this.showMsg = true;
        }
        this.form = this.fb.group({
          Password: ['', [Validators.required, Validators.minLength(6)]]
        });
      },
      (error) => {
        console.log(error);
        this.msg = "El token de restablecimiento de contraseña ha caducado.";
        if (this.msg.length > 1)
        {
          this.showError = true;
        }
      });
  }
}
