import { Component } from '@angular/core';
import { fromEvent, Observable, Subscription } from "rxjs";
import { Output, EventEmitter, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private readonly googleAnalyticsService: GoogleAnalyticsService) {}

  resizeObservable$: Observable<Event>
resizeSubscription$: Subscription
  title = 'MundoCercaDeTi';
  visible: boolean;//= true;
  ngOnInit() {
    if (environment.production && environment.gtagId) {
      this.googleAnalyticsService.initialize()
    };
    this.getCookie();  
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
    })
}
ngOnDestroy() {
  this.resizeSubscription$.unsubscribe()
}

@Output() close: EventEmitter<any> = new EventEmitter();

onGRDP() {
  this.visible = !this.visible;
    if (this.visible) {        
      this.close.emit(null);
    }
    //this.cookieService.set( 'Cookie', 'accepted' );
    window.localStorage.setItem('Cookie','accepted');
}
getCookie()
{ 
  if(window.localStorage.getItem('Cookie') == 'accepted')
  {
    this.visible = false;
  }
  else
  {    
    this.visible = true;
  }
  return window.localStorage.getItem('Cookie');
}

}
