<div [ngClass]="{'main-bible-study':isNotMobileMenu(),'main-mobile-bible-study':!isNotMobileMenu()}" *ngIf="isMobile()">
    <br>
    <div class="row header-bible-study-container">
        <table *ngIf=!isNotMobileMenu()> 
            <tr>
                <td>
                    <div class="header-bible-study-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Estudios Bíblicos</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/bible-studies" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row" *ngIf="isNotMobileMenu()">
            <div class="col-sm-5 col-xs-5 header-bible-study-container-margin">
                <label class="vertical-line-red" [ngClass]="{'fira-sans-strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()" >&nbsp;Estudios Bíblicos</label>
            </div>
            <div class="col-sm-6 col-xs-6 look-more">
                <a href="/bible-studies" >
                    <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                </a>
            </div>
        </div>
    </div>

    <div class="row">
            <div *ngIf="isNotMobileMenu()" class="col-sm-6" style="padding-left: 40px; padding-right: 35px; padding-top: 15px; display: flex; justify-content: center; align-items: center; flex-direction: column;"> 
                <p [ngClass]="{'fira-sans':isNotMobileMenu(),'mobil-fira-sans':!isNotMobileMenu()}" ng-if="isNotMobileMenu()" style="opacity: 0.6;">
                   <strong> {{ biblestudyViewModel.Title }} </strong> 
                </p>
                <p [ngClass]="{'text-fira-sans':isNotMobileMenu(),'mobil-fira-sans':!isNotMobileMenu()}" ng-if="isNotMobileMenu()" style="opacity: 0.6;">
                    {{ biblestudyViewModel.Introduction }}
                </p>
               
            </div>
            <div *ngIf="isNotMobileMenu()" class="col-sm-6 bible-study-cover"style="padding-left: 35px; padding-right: 40px;">
                <div class="thumbnail">
                    <img [src]="biblestudyViewModel.Image" alt="Lights" style="width:100%">
                </div>
            </div>

            <div class="row alieancion" *ngIf="isNotMobileMenu()" style="width: 50%; justify-content: flex-end;">
                <div style="width: auto;"> 
                    <a class="fira-sans-news vertical-class left" href="/bible-study-read/{{biblestudyViewModel.IdBibleStudie}}">Seguir leyendo</a>
                </div>
            </div>
            <!--Mobile view-->
            <div *ngIf="!isNotMobileMenu()" class="col-sm-12 bible-study-cover"style="padding: 10px 30px">
                <div class="thumbnail">
                    <img [src]="biblestudyViewModel.Image" alt="Lights" style="width:100%">
                </div>
            </div>
            <div *ngIf="!isNotMobileMenu()" class="col-sm-12" style="padding-left: 30px; padding-right: 30px;"> 
                <p class="fira-sans-news-title" style="opacity: 0.6;">
                   {{ biblestudyViewModel.Title }}
                </p>
                <p style="opacity: 0.6;">
                    {{ biblestudyViewModel.Introduction }}
                </p>
            </div>
            <div class="row" *ngIf="!isNotMobileMenu()" style="width: 100%;  padding-right: 30px;">
                <div class="col-sm-12">
                    <a class="fira-sans-news vertical-class left" href="/bible-study-read/{{biblestudyViewModel.IdBibleStudie}}">Seguir Leyendo</a>
                </div>
            </div>
            <!--Mobile view end-->
        <br>
        <br>
            <div *ngIf="true" class="carrusel-main-container">
                <div class="row" style="padding-left: 40px; padding-right: 40px;width: 100%;">
                    <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
                    <div class="carrusel-bible-container">
                        <div class="carrusel">
                                <button  class="btn bnt-style" *ngFor="let tag of tagViewModel;" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}">
                                    <a href="/bible-studies/{{tag.Id}}" >
                                        <div> 
                                            <img *ngIf=tag.Icon [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}" [src]="tag.Icon" style="width:100%;"/>
                                        </div>
                                    </a>
                                </button>
                        </div>
                    </div>
                    <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
                </div>
            </div>
        
    </div>
</div>

<div [ngClass]="{'main-bible-study':isNotMobileMenu(),'main-mobile-bible-study':!isNotMobileMenu()}" *ngIf="!isMobile()">
    <div class="row header-bible-study-container">
        <table *ngIf=!isNotMobileMenu()> 
            <tr>
                <td>
                    <div class="header-bible-study-container-margin">
                        <label [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Estudios Bíblicos</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/bible-studies" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div *ngIf="!isNotMobileMenu()" class="col-sm-12 bible-study-cover"style="padding: 10px 10px">
            <div class="thumbnail">
                <img class="studycover" [src]="biblestudyViewModel.Image" alt="Lights" style="width:100%">
            </div>
        </div>
        <div *ngIf="!isNotMobileMenu()" class="col-sm-12" style="padding-left: 30px; padding-right: 30px;"> 
            <p class="fira-sans-news-title" style="opacity: 0.6;">
                {{ biblestudyViewModel.Title }}
            </p>
            <p class="fira-sans-news" style="opacity: 0.6;">
                {{ biblestudyViewModel.Introduction }}
            </p>
        </div>
        <div class="row" *ngIf="!isNotMobileMenu()" style="width: 100%;  padding-right: 30px;">
            <div class="col-sm-12">
                <a class="fira-sans-news vertical-class left" href="/bible-study-read/{{biblestudyViewModel.IdBibleStudie}}">Seguir Leyendo</a>
            </div>
        </div>
            <!--Mobile view end-->
        <br>
        <p class="fira-sans-news-title" style="opacity: 0.6; height: 10px; padding-top: 5px;">
            Temas de Estudio
        </p>
        <div>
            <div>
                <div class="scroll" >
                    <div class="slider-container" #myIdentifier id="containerpadre" [style.width.%] ='SliderContainerWidth'>  
                            <div class="containerhijo" style="padding:15px 0px 0px 0px; width: 15%;" *ngFor="let tag of tagViewModel; let o = odd" >
                                <button  class="btn bnt-style">
                                    <a href="/bible-studies/{{tag.Id}}">
                                        <div> 
                                            <img *ngIf=tag.Icon [src]="tag.Icon" [ngClass]="{'mobil-item':!isNotMobileMenu(), 'odditem':o}" style="width:115%; height: 50px;"/>
                                        </div>
                                    </a>
                                </button>
                            </div>
                        
                    </div> 
                </div>
            </div> 
        </div>
        
    </div>
</div>
