import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { MainCoverPost} from '../models/main-cover-post';
import { TagViewModel} from '../models/tag-view-model';
import { ConfigurationViewModel} from '../models/configuration-view-model';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Dailyverses} from '../models/dailyverses';
declare const $: any;
@Component({
  selector: 'app-main-cover',
  templateUrl: './main-cover.component.html',
  styleUrls: ['./main-cover.component.css']
})
export class MainCoverComponent implements OnInit {
 
  mainCoverPostViewModel: MainCoverPost = new MainCoverPost();
  tagViewModel: TagViewModel[];
  dailyverse : Dailyverses = new Dailyverses();
  userLogged : boolean = false;
  url: SafeResourceUrl;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  constructor(private api: ApiService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getVideoValue();
    this.getMainCover();
    this.getTags();
    this.userLogged = this.api.isAuthenticated();
    this.getDailyVerse();
  }
  getDailyVerse()
  {
    this.api.get("/api/enabledailyverse").subscribe((response: any) => {
        this.dailyverse = response
      }
    )
  }
  ImageDailyVerse() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.dailyverse.URL);
  }

  getMainCover()
  {
    this.api.get("/api/mainpost").subscribe((response: any) => {
      this.mainCoverPostViewModel = response
      this.mainCoverPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.mainCoverPostViewModel.URLVideo:this.mainCoverPostViewModel.URLVideoBackUp);
      if(this.mainCoverPostViewModel.YTCensored == false)
      {
        this.mainCoverPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.mainCoverPostViewModel.URLVideo:this.mainCoverPostViewModel.URLVideoBackUp);
      }
      else if (this.mainCoverPostViewModel.YTCensored == true)
      {
        this.mainCoverPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.mainCoverPostViewModel.URLVideo);
      }
      }
    )
  }
  getTags()
  {
    this.api.get("/Tag/GetTags").subscribe((response: any) => {
      this.tagViewModel = response
      }
    )
  }
   videoURL(URLVideo: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(URLVideo);
  }
  doFilter(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth;
  }
  doLeft(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth;
  }
  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.mainCoverPostViewModel.URLImage);
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
getVideoValue()
{
  this.api.get("/Configuration?key=Video").subscribe((response: any) => {
    this.configurationViewModel = response
    }
  )
}
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};
}
