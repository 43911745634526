import { DefaultUrlSerializer, UrlSegmentGroup, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        const urlTree = super.parse(url);

        // Convert URL path to lower case.
        this.lowerCaseUrl(urlTree.root);

        return urlTree;
    }

    private lowerCaseUrl(urlSG: UrlSegmentGroup) {
        // Recursively check for and process child segments.
        if (urlSG.hasChildren()) {
            Object.entries(urlSG.children).forEach(
                ([key, value]) => this.lowerCaseUrl(value)
            );
        }

        // Convert URL segment path to lower case.
        urlSG.segments.forEach((seg) => seg.path = seg.path.toLowerCase());
    }
}