<div  *ngIf="!isMobile()" class="row fira-sans PaddingArti">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div style="background-color: rgb(41, 67, 116); width: 65%;  border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 40px;">
            <p class="fira-sans-strong-mobil">Leer la Biblia</p>
        </div>
    </div>
</div>

<div class="row cover-text" *ngIf="isMobile()" >
    <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
        <div class="row right">
            <app-sidemenu></app-sidemenu>
        </div>
    </div>
 
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div class="">
                <img class="dailyversecss" [src]='ImageURL()'>
            </div>
        </div>
    </div>   
</div>

<div class="row cover-text" *ngIf="!isMobile()">
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div class="">
                <img style="border-radius: 25px;" class="dailyversecss" [src]='ImageURL()'>
            </div>
        </div>
    </div>   
</div>

<div class="row" style="padding-top: 30px;"*ngIf="isMobile()">
    <div *ngIf="!isNotMobileMenu()" class="col-sm-12">
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 15px; padding-left: 5px; padding-right: 0px;">
            <div class="">
                <a href='/bible-read-plan'>
                    <img src='/assets/img/PostList/PlandelecturaMobile.jpg' class="PlanLectura mx-auto d-block img-fluid" style="width: 95%; max-height: 250px;">
                </a>
            </div>
        </div>
        
    </div>
    <div *ngIf="isNotMobileMenu()" class="col-sm-3" style="padding-left: 15px;">
        <a href='/bible-read-plan'>
            <img class="BiblePlan" src='/assets/img/PostList/Plandelectura.jpg' width="309px" height="309px">
        </a>
    </div>
    <div class="row vertical-class-text main-content" style="padding-left: 25px;" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
        <div class="header-profecy-container-margin col-sm-12" style="padding-left: 10px; max-height: 70px;">
            <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Leer la Biblia</label>
        </div>
        <div class="header-profecy-container-margin col-sm-12 row SearchStyle" style="z-index: 1;">
            <div class="col-sm-4 row" style="padding-right: 15px;">
                <div class="form-floating mb-3 mt-3">
                    <select class="form-select SelectColor" id="sel1" name="sellist1" [(ngModel)]="activeBook" (ngModelChange)="getBookChapters(activeBook)">
                        <option *ngFor ="let book of bibleBooks" [ngValue]="book.IdBook">{{book.Name}}</option>
                    </select>
                    <label for="sel1" class="form-label"><h5>Libro</h5></label>
                </div>
            </div>
            <div class="col-sm-3 row" style="padding-right: 15px;">
                <div class="form-floating mb-3 mt-3">
                    <select class="form-select SelectColor" id="sel2" name="sellist2" [(ngModel)]="activeChapter" (ngModelChange)="getChapterVerse(activeBook, activeChapter)">
                        <option *ngFor ="let chapter of bookChapters;let i = index" [ngValue]="i+1">{{i+1}}</option>
                    </select>
                    <label for="sel2" class="form-label"><h5>Capítulo</h5></label>
                </div>
            </div>
            <div class="col-sm-3 row" style="padding-right: 15px;">
                <div class="form-floating mb-3 mt-3">
                    <select class="form-select SelectColor" id="sel3" name="sellist3" [(ngModel)]="activeVerse">
                        <option *ngFor ="let chapterverse of chapterVerses;let i = index">{{i+1}}</option>
                    </select>
                    <label for="sel3" class="form-label"><h5>Versículo</h5></label>
                </div>
            </div>
            <div class="col-sm-2 btnsearch" style="padding-top: 16px;">
                <button  class="btn btn-primary btn-lg BuscarColor" id="Buscar" name="Buscar" (click)= "getData()"><i class="bi bi-search"></i> Buscar</button>
            </div>

        </div>
        <div class="vertical-class-text main-content col-sm-12" style="margin-top: -45px;" [ngClass]="{'divtextbible':!response.text}">
            <p *ngIf="response.text" [innerHTML]="_sanitizer.bypassSecurityTrustHtml(response.text)">
            <div class="row" *ngIf="response.text">
                <div class="col-sm-2" style="display: flex; align-items: center; justify-content: center;">
                    <a href="http://biblia.com/"><img src="http://api.biblia.com/v1/PoweredByBiblia.png" alt="Powered by Biblia" /></a>
                </div>
                <div class="col-sm-10" style="display: flex; align-items: center; justify-content: center; padding-left: 5px;">
                    <p  class="main-content-opacy">Texto bíblico Reina-Valera 1960® © Sociedades Bíblicas en América Latina, 1960. Derechos renovados 1988, Utilizado con permiso Reina-Valera 1960 ® es una marca registrada de las Sociedades Bíblicas
                        Unidas y puede ser usada solo bajo licencia.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row star-footer1" *ngIf="!isMobile()">
    <div class="star-footer-row-border-mobil"> 
    </div>
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row-mobil">
        <div>
            <a [href]='dailyverse.URL' [download]='dailyverse.URL'><label class="labelColor">Ver</label></a>
        </div>
    </div>
    <div style="width: 15px;">

    </div>
</div>

<div class="row" style="padding-top: 10px;" *ngIf="!isMobile()">
    <div class="row vertical-class-text main-content" style="padding-left: 0px;" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
        <div class="header-profecy-container-margin row SearchStyle" style="z-index: 1;">
            <div class="col-5" style="padding-right: 10px;">
                <div class="form-floating mb-3 mt-3">
                    <select class="form-select SelectColor" style="text-align: center;" id="sel1" name="sellist1" [(ngModel)]="activeBook" (ngModelChange)="getBookChapters(activeBook)">
                        <option *ngFor ="let book of bibleBooks" [ngValue]="book.IdBook">{{book.Name}}</option>
                    </select>
                    <label for="sel1" class="form-label labelColor">Libro</label>
                </div>
            </div>
            <div class="col-5 row" >
                <div class="col-6" style="padding-right: 10px;">
                    <div class="form-floating mb-3 mt-3">
                        <select class="form-select SelectColor" style="text-align: center;" id="sel2" name="sellist2" [(ngModel)]="activeChapter" (ngModelChange)="getChapterVerse(activeBook, activeChapter)">
                            <option *ngFor ="let chapter of bookChapters;let i = index" [ngValue]="i+1">{{i+1}}</option>
                        </select>
                        <label for="sel2" class="form-label labelColor">Cap.</label>
                    </div>
                </div>
                <div class="col-6" style="padding-right: 10px;">
                    <div class="form-floating mb-3 mt-3">
                        <select class="form-select SelectColor" style="text-align: center;" id="sel3" name="sellist3" [(ngModel)]="activeVerse">
                            <option *ngFor ="let chapterverse of chapterVerses;let i = index">{{i+1}}</option>
                        </select>
                        <label for="sel3" class="form-label labelColor">Vrs.</label>
                    </div>
                </div>
            </div>
            <div class="col-2 btnsearch" style="padding-top: 16px;">
                <button  class="btn btn-primary btn-lg BuscarColor" style="display: flex; justify-content: center; align-items: center;" id="Buscar" name="Buscar" (click)= "getData()">
                    <i class="bi bi-search"></i>
                </button>
            </div>
        </div>
        <div class="vertical-class-text main-content col-sm-12" style="margin-top: -85px; z-index: 0;" [ngClass]="{'divtextbible':!response.text}">
            <img src='/assets/img/blankfont.png' style="width: 100%; max-height: 250px;">
        </div>
        <div class="vertical-class-text main-content col-sm-12" style="margin-top: 0px;" [ngClass]="{'divtextbible':!response.text}">
            <p *ngIf="response.text" [innerHTML]="_sanitizer.bypassSecurityTrustHtml(response.text)">
            <div class="row" *ngIf="response.text">
                <div class="col-sm-2" style="display: flex; align-items: center; justify-content: center;">
                    <a href="http://biblia.com/"><img src="http://api.biblia.com/v1/PoweredByBiblia.png" alt="Powered by Biblia" /></a>
                </div>
                <div class="col-sm-10" style="display: flex; align-items: center; justify-content: center; padding-left: 5px;">
                    <p  class="main-content-opacy">Texto bíblico Reina-Valera 1960® © Sociedades Bíblicas en América Latina, 1960. Derechos renovados 1988, Utilizado con permiso Reina-Valera 1960 ® es una marca registrada de las Sociedades Bíblicas
                        Unidas y puede ser usada solo bajo licencia.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="padding-top: 50px;">
</div>