<div class="main-news-container" *ngIf=isMobile()>
    <div class="row header-news-container">
        <table *ngIf="!isNotMobileMenu()  && isSmallMobile()"> 
            <tr>
                <td>
                    <div class="header-news-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Revista MM</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Revista" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <table *ngIf="!isNotMobileMenu()  && !isSmallMobile()"> 
            <tr>
                <td>
                    <div class="header-news-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Revista El Mundo de Mañana</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Revista" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row" *ngIf="isNotMobileMenu()">
            <div class="col-sm-5 col-xs-5 header-news-container-margin">
                <label class="vertical-line-red" style="padding-right: 15px;" [ngClass]="{'fira-sans-news-strong':isNotMobileMenu(),'fira-sans-news-mobil strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Revista El Mundo de Mañana</label>
            </div>
            <div class="col-sm-2 col-xs-2 look-more">
                <a href="/posts?PostType=Revista">
                    <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                </a>
            </div>
        </div>
       
    </div>
    <span class="red"></span>
    <div *ngIf="isNotMobileMenu()" class="carrusel-main-review-container">
        <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
        <div class="carrusel-review-container">
            <div class="carrusel">
                <div [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}" *ngFor="let post of mainReviewPostViewModel">
                    <a href="/post/{{post.IdPost}}">
                        <img class="imagen"  [src]="post.URLImage">
                    </a>
                        <p class="fira-sans-news-title">{{post.Title}}</p>
                        <p style="padding-bottom: 15px;">{{post.Review}}</p>
                        <div class="row alieancion" style="justify-content: flex-end;">
                            <div style="padding-bottom: 10px; width: auto;"> 
                                <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                            </div>
                        </div>
              
                </div>
            </div>
        </div>
        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
    </div>
    <br *ngIf="!isNotMobileMenu()">
    <div *ngIf="!isNotMobileMenu()" id="carouselIndicators" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div *ngIf="mainReviewPostViewModel !=null" class="mobil-item">
                    <a href="/post/{{mainReviewPostViewModel[0].IdPost}}">
                        <img class="imagen"  [src]="mainReviewPostViewModel[0].URLImage">
                    </a>
                    <p class="fira-sans-news-title">{{mainReviewPostViewModel[0].Title}}</p>
                    <p>{{mainReviewPostViewModel[0].Review}}</p>
                    <div class="row alieancion-mobile">
                        <div class="vertical-class left">
                            <button type="button"  class="fira-sans-news" style="padding: 0; border: none; background: none;">
                                <a *ngIf="mainReviewPostViewModel[0].Content" style="padding-right: 0px;" href="/post/{{mainReviewPostViewModel[0].IdPost}}">
                                    <label>Seguir leyendo</label>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="carousel-item" *ngFor="let post of mainReviewPostViewModel | slice:1; let i = index">
                <div class="mobil-item">
                    <a href="/post/{{post.IdPost}}">
                        <img class="imagen"  [src]="post.URLImage">
                    </a>
                    <p class="fira-sans-news-title">{{post.Title}}</p>
                    <p>{{post.Review}}</p>
                    <div class="row alieancion-mobile">
                        <div class=" vertical-class left">
                            <button type="button" style="padding: 0; border: none; background: none;">
                                <a *ngIf="post.Content" style="padding-right: 0px;" class="fira-sans-news" href="/post/{{post.IdPost}}">
                                    <label>Seguir leyendo</label>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
                  
            </div>
        </div>
        <ol class="carousel-indicators">
            <li data-bs-target="#carouselIndicators" data-bs-slide-to="0" class="active"></li>
            <span *ngFor ="let post of mainReviewPostViewModel | slice:1; index as i;">
                <li data-bs-target="#carouselIndicators"   [attr.data-bs-slide-to]="i+1"></li>
            </span>
        </ol>
    </div>
    <br>
</div>

<div class="main-profecy-container" *ngIf=!isMobile()>
    <div class="row header-profecy-container">
        <table> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin">
                        <label [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Revista MM</label>
                    </div>
                </td>
                <td style="padding-top: 10px;">
                    <div class="look-more">
                        <a href="/posts?PostType=Revista" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div>
            <div>
                <div class="scroll">
                    <div class="slider-container">  
                        <div style="padding:5px 10px 0px 10px; display: grid;" *ngFor="let post of mainReviewPostViewModel">
                            <a class="fira-sans-news vertical-class left" style="display: flex; align-items: flex-start;" href="/post/{{post.IdPost}}">
                                <img class="slider-item" [src]="post.URLImage">
                            </a>
                            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-6" style="padding-top: 10px;">
                                <p class="fira-sans-news strong">{{post.Title}}</p>
                            </div>
                            <div class="row" style="display: flex; justify-content: flex-end; align-content: flex-end; padding-right: 15px;">
                                <div style="width: auto;"> 
                                    <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 
        </div>

    </div>
</div>
<br>