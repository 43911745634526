import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { LoginViewModel } from '../../models/login-view-model';
import { RegistrationViewModel } from '../../models/registration-view-model';
import { ConfigurationViewModel } from '../../models/configuration-view-model';
import { CountryViewModel } from '../../models/country-view-model';
import { EstateCountry } from '../../models/estate-country';

import { Router } from '@angular/router';

declare const $: any;
@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.css'],
})
export class TopnavbarComponent implements OnInit {
  CountryViewModel: CountryViewModel[];
  EstateCountry: EstateCountry[];
  activeCountry: CountryViewModel;
  userFullName: string;
  mision: string;
  userCountry: string;
  showThermometer: boolean = true;
  userLogged: boolean = false;
  loginViewModel: LoginViewModel = new LoginViewModel();
  registrationViewModel: RegistrationViewModel = new RegistrationViewModel();
  form: UntypedFormGroup;
  formRegistration: UntypedFormGroup;
  showMsg: boolean = false;
  configurationViewModel: ConfigurationViewModel = new ConfigurationViewModel();

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private router: Router
  ) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.formRegistration = this.fb.group({
      UserName: ['', [Validators.required]],
      Password: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      Country: [CountryViewModel, Validators.required],
      IdState: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCountries();
    this.userLogged = this.api.isAuthenticated();
    this.userFullName = sessionStorage.getItem('Name');
    this.userCountry = sessionStorage.getItem('Country');
    this.getMision();
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
      return true;
    }
    return false;
  }

  isMobile() {
    if ($(window).width() > 460) {
      return true;
    }
    return false;
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '250px';
  }

  MenuGrande() {
    if (document.getElementById('menu').style.height == '75px') {
      document.getElementById('menu').style.height = 'auto';
      document.getElementById('ButtonMas').innerHTML =
        '<span id="icono" class="bi bi-dash-circle-fill" style="color: rgb(57,155,220);"></span>';
      document.getElementById('icono').className = 'bi bi-dash-circle-fill';
      document.getElementById('MasButton').textContent = 'Menos';
    } else {
      document.getElementById('menu').style.height = '75px';
      document.getElementById('ButtonMas').innerHTML =
        '<span id="icono"  class="bi bi-plus-circle-fill" style="color: rgb(57,155,220);"></span>';
      document.getElementById('icono').className = 'bi bi-plus-circle-fill';
      document.getElementById('MasButton').textContent = 'Más';
    }
  }

  login() {
    var signUpViewModel = this.form.value as LoginViewModel;

    this.api.Login(signUpViewModel).subscribe(
      () => {
        window.location.reload();
      },
      (error) => {
        console.log(error.error);
        this.showMsg = true;
        this.form = this.fb.group({
          username: [''],
          password: [''],
        });
      }
    );
  }

  checkUserName() {
    this.api
      .get('/api/checkuser/' + this.formRegistration.controls['UserName'].value)
      .subscribe((response: any) => {
        if (response) {
          this.formRegistration.controls['UserName'].setErrors({
            notUnique: true,
          });
        }
      });
  }

  

  register() {
    this.checkUserName();

    if (this.formRegistration.invalid) {
      this.formRegistration.markAllAsTouched();
      return;
    }

    var registrationViewModel = this.formRegistration
      .value as RegistrationViewModel;
      registrationViewModel.IdCountry = this.formRegistration.value.Country.IdCountry;

    this.api.post('/api/suscriber', registrationViewModel).subscribe(
      () => {
        window.location.reload();
      },
      (error) => {
        console.log(error);
        this.showMsg = true;
        this.formRegistration = this.fb.group({
          UserName: [''],
          Password: [''],
          FirstName: [''],
          LastName: [''],
          Email: [''],
          IdCountry: [''],
          IdState: [''],
        });
      }
    );
  }

  logout() {
    this.api.Logout();
    window.location.reload();
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  getMision() {
    this.api.get('/Configuration?key=Mision').subscribe((response: any) => {
      this.configurationViewModel = response;
    });
  }

  getCountries() {
    this.api.get('/api/countries').subscribe((response: any) => {
      this.CountryViewModel = response;
    });
  }
  getStates(IdCountry: number) {
    if (IdCountry) {
      this.api.get('/api/States/' + IdCountry).subscribe((response: any) => {
        this.EstateCountry = response;
      });
    }
  }
}
