import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainCoverComponent } from './main-cover/main-cover.component';
import { MainPostComponent } from './main-post/main-post.component';
import { MainPostListComponent } from './main-post-list/main-post-list.component';
import { JwtModule} from "@auth0/angular-jwt"
import { ContactusComponent } from './contactus/contactus.component';
import { BibleStudyReadComponent } from './bible-study-read/bible-study-read.component';
import { BibleStudyListComponent } from './bible-study-list/bible-study-list.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { ReadLaterListComponent } from './read-later-list/read-later-list.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BibleSearchComponent } from './bible/bible-search/bible-search.component';
import { BiblereadplanComponent } from './biblereadplan/biblereadplan/biblereadplan.component';
import { BiblereadplanprogressComponent } from './biblereadplan/biblereadplanprogress/biblereadplanprogress.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '',   component: MainCoverComponent },
  { path: 'home',   component: MainCoverComponent },
  { path: 'post/:PostId',   component: MainPostComponent },
  { path: 'posts',   component: MainPostListComponent },
  { path: 'contact',   component: ContactusComponent },
  { path: 'bible-study-read/:IdBiblestudies',   component: BibleStudyReadComponent },
  { path: 'bible-studies',   component: BibleStudyListComponent },
  { path: 'bible-studies/:IdTag',   component: BibleStudyListComponent },
  { path: 'favorite-post',   component: FavoriteListComponent },
  { path: 'read-later-post',   component: ReadLaterListComponent },
  { path: 'forgot-password',   component: ForgotPasswordComponent },
  { path: 'reset-password',   component: ResetPasswordComponent },
  { path: 'bible',   component: BibleSearchComponent },
  { path: 'bible-read-plan',   component: BiblereadplanComponent },
  { path: 'bible-read-plan-progress',   component: BiblereadplanprogressComponent },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    JwtModule.forRoot({
      config:{
        tokenGetter: () => {
          return (sessionStorage.getItem("currentUser")? JSON.parse(sessionStorage.getItem("currentUser")).Token : null)
        }
      }
    }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
