import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MainCoverPost} from '../models/main-cover-post';
declare const $: any;

@Component({
  selector: 'app-main-question',
  templateUrl: './main-question.component.html',
  styleUrls: ['./main-question.component.css']
})
export class MainQuestionComponent implements OnInit {
  mainQuestionPostViewModel: MainCoverPost[];
  constructor(private api: ApiService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getMainQuestionPost();
  }

  getMainQuestionPost()
  {
    this.api.get("/api/mainposts/1/10").subscribe((response: any) => {
      this.mainQuestionPostViewModel = response;
      }
    )
  }
  doFilter(){
    document.querySelector('.carrusel-question-container').scrollLeft += document.querySelector('.carrusel-main-question-container').scrollWidth;
    console.log(document.querySelector('.carrusel-container').scrollWidth);
  }
  doLeft(){
    document.querySelector('.carrusel-question-container').scrollLeft = document.querySelector('.carrusel-question-container').scrollLeft - document.querySelector('.carrusel-main-question-container').scrollWidth;
  }
  ImageURL(URLImage: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(URLImage);
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isSmallMobile() {
  if ($(window).width() < 450) {
      return true;
  }
  return false;
};
}