import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BibleBookModel} from '../../models/bible-book';
import { Dailyverses} from '../../models/dailyverses';
declare const $: any;
@Component({
  selector: 'app-bible-search',
  templateUrl: './bible-search.component.html',
  styleUrls: ['./bible-search.component.css']
})
export class BibleSearchComponent implements OnInit {
  response : any=[]
  dailyverse : Dailyverses = new Dailyverses();
  bibleBooks : BibleBookModel[]
  bookChapters : number[]
  chapterVerses : number[]
  activeBook: number = 1
  activeChapter: number = 1
  activeVerse: number = 1
  constructor(private api: ApiService,public _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    
    this.getBibleBooks();
    this.getBookChapters(1);
    this.getChapterVerse(1,1);
    this.getDailyVerse();
  }

  getDailyVerse()
  {
    this.api.get("/api/enabledailyverse").subscribe((response: any) => {
        this.dailyverse = response
      }
    )
  }
  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.dailyverse.URL);
  }
  URL365Banner() {
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://elmundodemananacercadeti.mediafire.com/file/wiiau0b7cxqznri/Emperadores.jpg/file');
  }
  getData()
  {
          this.api.get("/api/biblequote?idBook="+this.activeBook+"&chapter="+ this.activeChapter+"&verse="+this.activeVerse+"#Verso").subscribe((response: any) => {
        this.response = response
        }
      )
      setTimeout( () => { document.getElementById('Verso').scrollIntoView(); }, 1500 );    
  }
  getBibleBooks()
  {
    this.api.get("/api/biblebooks").subscribe((response: any) => {
      this.bibleBooks = response
      this.activeChapter = 1
      this.activeVerse = 1
      }
    )
  }
  getBookChapters(idBook:number)
  {
    console.log(idBook)
    this.api.get("/api/bookchapters?idBook="+idBook).subscribe((response: any) => {
      this.bookChapters = response
      this.activeChapter = 1
      this.activeVerse = 1
      this.api.get("/api/chapterverses?idBook="+idBook+"&chapter="+this.activeChapter).subscribe((response: any) => {
        this.chapterVerses = response
        this.activeVerse = 1
        }
      )
      }
    )
  }
  getChapterVerse(idBook: number, chapter: number)
  {
    this.api.get("/api/chapterverses?idBook="+idBook+"&chapter="+chapter).subscribe((response: any) => {
      this.chapterVerses = response
      this.activeVerse = 1
      }
    )
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  };
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    
    return false;
  };
}
