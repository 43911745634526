import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Dailyverses} from '../../models/dailyverses';
import { Biblereadcurrentday} from '../../models/biblereadcurrentday';
import { BiblereadcurrentdayPost} from '../../models/biblereadcurrentday-post';
import { BiblereadplangridbypageDTO} from '../../models/biblereadplangridbypage-dto';
declare const $: any;

@Component({
  selector: 'app-biblereadplanprogress',
  templateUrl: './biblereadplanprogress.component.html',
  styleUrls: ['./biblereadplanprogress.component.css']
})
export class BiblereadplanprogressComponent implements OnInit {
  response : any=[]
  dailyverse : Dailyverses = new Dailyverses();
  biblereadcurrentday : Biblereadcurrentday = new Biblereadcurrentday();
  biblereadday : Biblereadcurrentday = new Biblereadcurrentday();
  BiblereadcurrentdayPost : BiblereadcurrentdayPost = new BiblereadcurrentdayPost();
  BiblereadplangridbypageDTO : BiblereadplangridbypageDTO;
  isSuscriberAuthenticated : boolean = false;
  IdUser : string;
  ActionButtonText : string = "Completar"
  constructor(private api: ApiService,public _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
    this.IdUser  = sessionStorage.getItem("currentUser");
    $(() => {
      var autenticad=this.api.isAuthenticated();
      if(autenticad == false)
      {
        $('#myModal').modal('toggle')
      }
    });

    this.getDailyVerse();

    if(this.isSuscriberAuthenticated==true)
    {
      this.getBiblereadCurrentDay();
      this.getProgressBibleReadPlan();
    }
  }
  getDailyVerse()
  {
    this.api.get("/api/enabledailyverse").subscribe((response: any) => {
        this.dailyverse = response
      }
    )
  }

  getBiblereadCurrentDay()
  {
    this.api.getWithAuthentication("/api/biblereadcurrentday").subscribe((response: any) => {
        this.biblereadcurrentday = response
        this.biblereadcurrentday.CurrentDay=this.biblereadcurrentday.CurrentDay-1;
        console.log(this.biblereadcurrentday);
      }

    )
  }
  CompleteDay(Day:number)
  {
      
      if(Day==365)
      {
        this.BiblereadcurrentdayPost.Day=Day;
        this.BiblereadcurrentdayPost.IdBibleReadPlan=this.biblereadcurrentday.IdBibleReadPlan;
        $(() => {
            $('#myModalFin').modal('toggle')
        });
        this.api.postWithAuthentication("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response: any) => {
        }
        )
        setTimeout( () => { window.location.assign("/home");}, 7000);    
      }
      if(Day==this.biblereadcurrentday.CurrentDay+1)
      {
        this.BiblereadcurrentdayPost.Day=Day;
        this.BiblereadcurrentdayPost.IdBibleReadPlan=this.biblereadcurrentday.IdBibleReadPlan;
        
          this.api.postWithAuthentication("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response) => {
            this.getProgressBibleReadPlan();
            this.getBiblereadCurrentDay();
          }
        )
        Day=Day+1;
        this.api.getWithAuthentication("/api/biblereadplanbyid/"+Day).subscribe((response: any) => {
          this.biblereadday = response
          console.log(this.biblereadday);
        }
        )
        document.getElementById('titlechapter').scrollIntoView();
      }
      else
      {
        Day=Day+1;
        this.api.getWithAuthentication("/api/biblereadplanbyid/"+Day).subscribe((response: any) => {
          this.biblereadday = response
          console.log(this.biblereadday);
        }
        )
        document.getElementById('titlechapter').scrollIntoView();
        }
    }

  getBiblereadDay(Day:number)
  {
    if($('#titlechapter').length<=0 || $('#titlechapter').length=='unDefined')
    {
      setTimeout( () => { document.getElementById('titlechapter').scrollIntoView();}, 1500); 
    }

      this.api.getWithAuthentication("/api/biblereadplanbyid/"+Day).subscribe((response: any) => {
        this.biblereadday = response
        console.log(this.biblereadday);
      }
    )

    document.getElementById('titlechapter').scrollIntoView();
  }

  getProgressBibleReadPlan()
  {
    this.api.getWithAuthentication("/api/availablebiblereadplans").subscribe((response: any) => {
        this.BiblereadplangridbypageDTO = response
        if(this.BiblereadplangridbypageDTO.PlanCompleted)
        {
          this.ActionButtonText = "Cerrar"
        }
        console.log(this.BiblereadplangridbypageDTO);
      }
    )
  }


  CompleteCurrentDay(Day:number, IdBibleReadPlan:number)
  {
    this.BiblereadcurrentdayPost.Day=Day;
    this.BiblereadcurrentdayPost.IdBibleReadPlan=IdBibleReadPlan;
    if(this.BiblereadplangridbypageDTO.PlanCompleted)
    {
      window.location.reload();
    }
else{
  if(Day == 365)
  {
    $(() => {
      if(Day == 365)
      {
        $('#myModalFin').modal('toggle')
      }
    });
    this.api.post("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response: any) => {

    }
    )
    setTimeout( () => { window.location.assign("/bible-studies");}, 15000);
  }
  else
  {

    this.api.post("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response: any) => {
      }
    )
    window.location.reload();
  }
}
    

  }

  redirect()
  {
    window.location.assign("/bible-studies");
  }
  subir()
  {
      document.getElementById('titlechapter').scrollIntoView();
  }
  subirtitulo()
  {
      document.getElementById('titulo').scrollIntoView();
  }

  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.dailyverse.URL);
  }
  URL365Banner() {
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://elmundodemananacercadeti.mediafire.com/file/wiiau0b7cxqznri/Emperadores.jpg/file');
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  };

  isNotPhone() {
    if ( $(window).width() < 767) {
        return true;
    }
    return false;
  };

  
  isPhone() {
    if ($(window).width() < 400) {
        return true;
    }
    return false;
  };


  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    
    return false;
  };
}
