import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent implements OnInit {

  @Input() eeaCountry: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
