<div class="contact-us">
    <div class="row contact-us-header fira-sans">
    </div>
    <div class="row" *ngIf="showMsg">
        <div class="col-1 col-sm-2 col-md-3 col-lg-4">
        </div>
        <div class="col-10 col-sm-8 col-md-6 col-lg-4">
         
        </div>
    </div>
    <div class="row">
        <div class="col-1 col-sm-2 col-md-3 col-lg-4">
        </div>
        <div class="col-10 col-sm-8 col-md-6 col-lg-4" style="border: 3px solid rgb(189, 215, 239); border-radius: 15px;">
            <div> 
                <div class="card-body"> 
                    <div class="col-sm-12 contact-us-next" style="text-align: center;">
                        <div class="container">
                            <div class="row" style="padding-top: 15px; padding-bottom: 15px;">
                                <div class="col">
                                    <img src="/assets/img/ResetPassword.png" height="90%" width="40%"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h1>Actualiza tu contraseña</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="form" autocomplete="off">
                        <div style="padding-bottom: 30px;">   
                            <div *ngIf="showMsg" style="text-align: center;">
                                <p class="alert alert-success">
                                    <strong>{{msg}}</strong>
                                </p>
                            </div>
                            <div *ngIf="showError" style="text-align: center;">
                                <p class="alert alert-danger">
                                    <strong>{{msg}}</strong>
                                </p>
                            </div>
                            <div class="forgot-email">
                                <input style="background-color: #dee2e4 !important; border-radius: 10px;" type="password" class="form-control" id="Password" placeholder="Ingresa nueva contraseña" formControlName="Password">
                            </div>
                        </div>
                    </form>
                    <div class="col-sm-12 contact-us-next" style="padding-top: 10px;">
                        <button type="button" style="border-radius: 10px; padding-right: 25px; padding-left: 25px;" class="btn mb-2" (click)="resetPassword()" >
                            <label class="fira-sans">Actualizar contraseña</label>
                        </button>
                    </div>
                </div> 
            </div>        
        </div>
        <div class="col-1 col-sm-8 col-md-2"></div>
    </div>
</div>

