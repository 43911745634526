import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend  } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginViewModel } from '../models/login-view-model';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiServer = environment.apiServer;
  private httpClientLogin : HttpClient;
  currentUserName: string  = null;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'  ,
      
    })
  }
  constructor(private httpClient: HttpClient,private httpBackend: HttpBackend, private jwtHelperService : JwtHelperService) { }
   get(endpoint): Observable<any[]> {
     return this.httpClient.get<any[]>(this.apiServer + endpoint,{ responseType:"json"});
   }
   post(enpoint:string,model: any): Observable<any>
   {
     return this.httpClient.post<any>(this.apiServer + enpoint, model, { responseType: "json" });
   }
   put(enpoint:string,model: any): Observable<any>
   {
     return this.httpClient.put<any>(this.apiServer + enpoint, model, { responseType: "json" });
   }
   getWithAuthentication(endpoint): Observable<any[]> {
     var currentUser = {Token :""};
     var headers = new HttpHeaders();
     headers = headers.set("Authorization", "Bearer");
     if(sessionStorage.currentUser != null)
     {
       currentUser =JSON.parse(sessionStorage.currentUser);
       headers = headers.set("Authorization", "Bearer " + currentUser.Token);
       
       this.httpOptions.headers.set("Authorization","Bearer " + currentUser.Token);
     } 
     return this.httpClient.get<any[]>(this.apiServer + endpoint,{ responseType:"json",headers:headers});
   }
   postWithAuthentication(enpoint:string,model: any): Observable<any[]> {
    var currentUser = {Token :""};
    var headers = new HttpHeaders();
    headers = headers.set("Authorization", "Bearer");
    if(sessionStorage.currentUser != null)
    {
      currentUser =JSON.parse(sessionStorage.currentUser);
      headers = headers.set("Authorization", "Bearer " + currentUser.Token);
      
      this.httpOptions.headers.set("Authorization","Bearer " + currentUser.Token);
    } 
    return this.httpClient.post<any>(this.apiServer + enpoint, model, { responseType: "json",headers:headers });
  }
   public Login(loginViewModel: LoginViewModel): Observable<any>
  {
    this.httpClientLogin = new HttpClient(this.httpBackend);
    return this.httpClientLogin.post<any>(this.apiServer + "/authenticateSuscriber", loginViewModel, { responseType: "json" })
    .pipe(map(user => {
      if (user)
      {
        this.currentUserName = user.UserName;
        sessionStorage.currentUser = JSON.stringify(user);
        sessionStorage.Name = user.Name;
        sessionStorage.Country = user.Country
      }
      return user;
    }));
  }

  public Logout()
  {
    sessionStorage.removeItem("currentUser");
    this.currentUserName = null;
  }
  public isAuthenticated() : boolean
  {
    var token = sessionStorage.getItem("currentUser")? JSON.parse(sessionStorage.getItem("currentUser")).Token : null; 
     if (this.jwtHelperService.isTokenExpired(token))
     {
       return false; //token is not valid
     }
     else
     {
       return true; //token is valid
     }
  }
}
