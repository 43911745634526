<div class="main-footer">
    <div class="row main-footer-items justify-content-center">
        <div class="center-class col-xs-1 col-sm-3 col-md-3">
            <a href="/home" class="simple-text">
                <div class="center-img">
                    <img src="/assets/img/footer/MundoLogo.png" height="70%" width="70%"/>
                </div>
            </a>
        </div>
        <div class="col-xs-5 col-sm-4 col-lg-3">
            <div class="vertical-line-red">
                <p class="fira-sans-strong">Inicio</p>
                <div class="footer-item">
                    <a class="fira-sans" href="/contact">Contáctenos</a>
                </div>
                
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.elmundodemanana.org/folletos">Otras Publicaciones</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.elmundodemanana.org/comunicarse/donaciones">Donaciones</a>
                </div>
            </div>
            
        </div>
        <div class="col-xs-6 col-sm-5 col-md-4">
            <div class="vertical-line-red">
                <p class="fira-sans-strong">Internacional</p>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.tomorrowsworld.org/">Tomorrow's World</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.mondedemain.org/">Le Monde de Demain</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.weltvonmorgen.org/">Die Welt von Morgen</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.wereldvanmorgen.nl/">Wereld van Morgen</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.wvm.co.za/">Wereld van More</a>
                </div>
                <div class="footer-item">
                    <a class="fira-sans" href="https://www.omundodeamanha.org/">O Mundo de Amanhã</a>
                </div>
            </div>
            
        </div>
    </div>
    
</div>
<div class="row">
    <div class="d-flex justify-content-between">
        <p class="fs-6">&copy;2023 El Mundo De Mañana - <a href="https://www.lcg.org/sites/default/files/legal/pdf/Privacy_Statement_GDPR_PS_122021_Multi-Lang.pdf#Spanish">Política de Privacidad</a></p>
        <p class="fs-6">Patrocinado por: <a href="https://www.lcg.org/">Living Church of God</a></p>
    </div>    
</div>
