<div class="main-profecy-container" *ngIf=isMobile()>
    <div class="row header-profecy-container">
        <table *ngIf=!isNotMobileMenu()> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Shorts</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Shorts" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row" *ngIf="isNotMobileMenu()">

            <div class="col-sm-4 header-profecy-container-margin">
                <label class="vertical-line-red" [ngClass]="{'fira-sans-strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Shorts</label>
            </div>
            <div class="col-sm-3 look-more">
                <a href="/posts?PostType=Shorts" >
                    <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="isNotMobileMenu()" class="carrusel-main-container">
        <div>
            <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
        </div>
        <div class="carrusel-shorts-container">
            <div class="carrusel">
                <div [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}" (onclick)="pauseVideo()" *ngFor="let post of mainFocusPostViewModel">
                    
                        <div style="z-index: 2;" class="youtube-video-container" (onclick)="pauseVideo()">
                            <iframe *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                   <div class="row align-title">
                        <p class="fira-sans">{{post.Title}}</p>
                        <p>{{post.Review}}</p>
                   </div>
                </div>
            </div>
        </div>
        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
    </div>
    <br *ngIf="!isNotMobileMenu()">
    <div *ngIf="!isNotMobileMenu()" id="carouselIndicatorShorts" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
        <br>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div *ngIf="mainFocusPostViewModel !=null" class="mobil-item">
                    <div style="z-index: 1;" class="youtube-video-container" (onclick)="pauseVideo()">
                        <iframe *ngIf="configurationViewModel.Value == 'YouTube' && mainFocusPostViewModel[0].YTCensored == false" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                        <video *ngIf="configurationViewModel.Value == 'MediaFire' && mainFocusPostViewModel[0].YTCensored == false" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        <video *ngIf="mainFocusPostViewModel[0].YTCensored" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    </div>
                    <p class="fira-sans-news-title">{{mainFocusPostViewModel[0].Title}}</p>
                    <p>{{mainFocusPostViewModel[0].Review}}</p>
                    <div class="row alieancion">
                        <div class="col-sm-12">
                            <a *ngIf="mainFocusPostViewModel[0].Content" style="padding-right: 0px;" class="fira-sans-news vertical-class left" href="/post/{{mainFocusPostViewModel[0].IdPost}}">Seguir leyendo</a>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="carousel-item" *ngFor="let post of mainFocusPostViewModel | slice:1; let i = index">
                <div class="mobil-item">
                    <div style="z-index: 2;" class="youtube-video-container" (onclick)="pauseVideo()">
                        <iframe *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                        <video *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        <video *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    </div>
                    <p class="fira-sans-news-title">{{post.Title}}</p>
                    <p>{{post.Review}}</p>
                </div>
                  
            </div>
          
        </div>
        <ol class="carousel-indicators">
            <li data-bs-target="#carouselIndicatorShorts" data-bs-slide-to="0" class="active"></li>
            <span *ngFor ="let post of mainFocusPostViewModel | slice:1; index as i;">
                <li data-bs-target="#carouselIndicatorShorts"   [attr.data-bs-slide-to]="i+1"></li>
            </span>
           
          </ol>
    </div>
</div>

<div class="main-profecy-container" *ngIf=!isMobile()>
    <div class="row header-profecy-container">
        <table> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin" style="padding-top: 3px; padding-bottom: 10px;">
                        <label [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Shorts</label>
                    </div>
                </td>
            </tr>
        </table>
        <div *ngIf="showMainVideo" style="padding-left: 10px; padding-right: 10px;">
            <div  style="padding:15px 10px 0px 10px;">
                <iframe class="corner-wrapper" *ngIf="configurationViewModel.Value == 'YouTube' && mainFocusPostViewModel[0].YTCensored == false" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                <video *ngIf="configurationViewModel.Value == 'MediaFire' && mainFocusPostViewModel[0].YTCensored == false" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                <video *ngIf="mainFocusPostViewModel[0].YTCensored" width="838" height="470" [src]='mainFocusPostViewModel[0].URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>   
            </div>
        </div>
            

            <div *ngIf="showCarrusel" class="col-xs-10 col-sm-10 col-md-10 col-lg-6" style="padding: 10px 20px 0px 20px">
                <p class="fira-sans-news strong">{{mainFocusPostViewModel[0].Title}}</p>
                 <P class="fira-sans-news">{{mainFocusPostViewModel[0].Review}}</P>
            </div>

        <table> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin" style="padding-top: 0px; padding-bottom: 3px;">
                        <a href="/posts?PostType=Shorts" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
                <td>
                </td>
            </tr>
        </table>
        
        <div>
            <div>
                <div class="scroll">
                    <div class="slider-container" #myIdentifier id="containerpadre">  
                            <div class="containerhijo" [style.max-width.px] ='itemWidth' style="padding:15px 10px 0px 10px;" *ngFor="let post of mainFocusPostViewModel | slice:1; let i = index">
                                <a class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">
                                    <iframe class="slider-item" *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                                    <video *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored == false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                                    <video *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                                </a>
                                <div style="padding-top: 10px; width: 100%">
                                    <p class="fira-sans-news strong">{{post.Title}}</p>
                                </div>
    
                            </div>
                        
                    </div> 
                </div>
            </div> 
        </div>
    </div>
</div>