
<div class="row main-top-container">
    <div *ngIf="!isNotMobileMenu() && isMobile()" class="col-11 vertical-class left">
        <a *ngIf="!isNotMobileMenu()" href="/home" class="simple-text">
            <div>
                <img  class="rounded mx-auto d-block img-fluid" src="/assets/img/MDMCercaDeTi.png" style="width: 30%;"/>
            </div>
        </a>
    </div>
    <div *ngIf="!isMobile()" class="col-12 vertical-class left">
        <div class="row">
            <div class="col-4">
                <a href="/home" class="simple-text">
                    <div>
                        <img  class="rounded img-fluid" src="/assets/img/MDMCercaDeTi.png" style="width: 100%;"/>
                    </div>
                </a>
            </div>
            <div  class="col-4 vertical-line-red" style="align-items: center;">
                <div class="row">
                    <div *ngIf="userLogged">
                        <label class="fira-sans">Bienvenido <br> <strong>{{userFullName}}</strong> <br><strong style="font-size: 85%;">{{userCountry}}</strong></label>
                    </div>            
                </div>
            </div>
            <div  class="col-4 vertical-line-gray-1">
                    <img src="/assets/img/sidebar/login.png" height="30" class="login-icon"/>
                    <a style="text-decoration: none; color: rgb(53,107,150);" *ngIf="!userLogged" href="#myModal" data-bs-toggle="modal" data-target="#myModal">
                        <strong>Ingresar</strong>
                    </a>
                    <div *ngIf="userLogged">
                        <button type="button" style="color: rgb(53,107,150);" class="btn btn-primary buttonaslink" (click)="logout()">
                            <strong>Salir</strong>
                        </button>
                    </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isNotMobileMenu() && isMobile()" class="col-1 vertical-class left">
        <span  style="font-size:30px;cursor:pointer" (click)="openNav()">&#9776; </span>
        <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
            <br>
            <a *ngIf="!userLogged" href="#myModal" data-bs-toggle="modal" data-target="#myModal">
                <img src="/assets/img/sidebar/login.png" height="40"/>
                Iniciar Sesión
            </a>
            <a *ngIf="!userLogged" href="#myModal2" data-bs-toggle="modal" data-target="#myModal2">
                <img src="/assets/img/sidebar/registrarse.png" height="40"/>
                Registrarse
            </a>
            <a *ngIf="userLogged" href="#" (click)="logout();">
                <img src="/assets/img/sidebar/Mision.png" height="40"/>
                Cerrar Sesión
            </a>
            <a href="/contact">
                <img src="/assets/img/sidebar/Contacto.png" height="40"/>
                    Contactenos
            </a>
            <a href="#myModal3" data-bs-toggle="modal" data-target="#myModal3">
                <img src="/assets/img/sidebar/Mision.png" height="40"/>
                Nuestra Misión
            </a>
            <a href="/posts?PostType=Noticias">
                <img src="/assets/img/sidebar/Profecia.png" height="40"/>
                Noticias y Profecía
            </a>
            <a href="/posts?PostType=Enfoque">
                <img src="/assets/img/sidebar/NuevoEnfoque.png" height="40"/>
                Un Nuevo Enfoque
            </a>
            <a href="/bible">
                <img src="/assets/img/sidebar/Biblia.png" height="40"/>
                    Leer la Biblia
            </a>
            <a href="/posts?PostType=Revista">
                <img src="/assets/img/sidebar/Revista.png" height="40"/>
                Revista
            </a>
            <a href="/bible-studies">
                <img src="/assets/img/sidebar/LeerBiblia.png" height="40"/>
                Estudios
            </a>
            <a href="/favorite-post">
                <img src="/assets/img/sidebar/Favoritos.png" height="40"/>
                &nbsp;&nbsp;Mis Favoritos
            </a>
            <a href="/read-later-post">
                <img src="/assets/img/sidebar/MasTarde.png" height="40"/>
                &nbsp;&nbsp;Ver Más Tarde
            </a>
          </div>
    </div>

    <div *ngIf="!isNotMobileMenu()" class="col-sm-3"></div>
    <div class="center-class" [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-6':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
        <a *ngIf="isNotMobileMenu()"href="/home" class="simple-text">
            <div>
                <img src="/assets/img/MDMCercaDeTi.png" style="width: 51%;"/>
            </div>
        </a>
    </div>
    <div *ngIf="!isNotMobileMenu()" class="col-sm-3">
        
    </div>
    <div *ngIf="isNotMobileMenu()" class="vertical-line-red col-sm-5 vertical-class">
        <div class="row">
            <div *ngIf="userLogged">
                <label class="fira-sans">Bienvenido  <strong>{{userFullName}}</strong></label>
                <br>
                <label class="fira-sans"><strong>{{userCountry}}</strong></label>
            </div>            
        </div>
        <div>
        </div>
    </div>
    <div *ngIf="isNotMobileMenu()"  class="col-sm-2 vertical-class">
        <div class="vertical-line-gray">
            <a href="/contact">
                <img src="/assets/img/sidebar/Contacto.png" height="40" class="login-icon" style="color: rgb(53,107,150);"/>
                <label class="fira-sans" style="padding-left: 5px; color: rgb(53,107,150); cursor:pointer;"><strong class="login-text-size">Contáctenos</strong></label>
            </a>
        </div>
    </div>
        <div *ngIf="isNotMobileMenu()" class="col-sm-2 vertical-class"> 
            <div class="vertical-line-gray">
                <img src="/assets/img/sidebar/login.png" height="30" class="login-icon"/>
                <div class="login-text">
                    <button *ngIf="!userLogged" type="button" style="color: rgb(53,107,150);"class="btn btn-primary buttonaslink" data-bs-toggle="modal" data-bs-target="#myModal">
                        <strong class="login-text-size">Iniciar Sesión</strong>
                    </button>
                    
                    <div *ngIf="userLogged">
                        <button type="button" style="color: rgb(53,107,150);" class="btn btn-primary buttonaslink" (click)="logout()">
                            <strong>Salir</strong>
                        </button>
                    </div>
                    <button *ngIf="!userLogged" type="button" style="color: rgb(53,107,150);" class="btn btn-primary buttonaslink" data-bs-toggle="modal" data-bs-target="#myModal2">
                        <strong class="login-text-size">Registrarse</strong>
                    </button>
                </div>       
            </div>
    </div>   
</div>
<!-- Menu para telefonos-->
<div *ngIf="!isMobile()" id="menu" class="menuClase" style="height: 75px;">

    <div class="container">
        <div class="row" style="text-align: center;" style="padding-top: 5px;">
            <div class="col MenuWidht mobil-menu-buttons" style="padding-top: 5px;">
                <a style="text-decoration: none;" href="/home">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/HOME.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Inicio</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons" style="padding-top: 5px;">
                <a style="text-decoration: none;" href="/bible">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/BibliaMobil.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Biblia</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons">
                <button id="ButtonMas" class="button StyleIcono" (click)="MenuGrande()" ><span id="icono" class="bi bi-plus-circle-fill"></span></button>
                <!-- <p id="MasButton">
                    Más
                </p> -->
                <label id="MasButton" style="font-size: 0.85em;">Más</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons" style="padding-top: 5px; margin-left: 0%; margin-right: 0%; padding-right: 10px; padding-left: 10px;">
                <a style="text-decoration: none;" href="/bible-read-plan">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/PLAN.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Plan 365</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons" style="padding-top: 5px;">
                <a style="text-decoration: none;" href="https://www.elmundodemanana.org/comunicarse/donaciones">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/donasionesiconmobil.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Donaciones</label>
            </div>
            <div class="w-100"></div>
            <div class="col MenuWidht mobil-menu-buttons">
                <a style="text-decoration: none; filter: grayscale(1);" href="/posts?PostType=Enfoque">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/NuevoEnfoque.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Enfoque</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons">
                <a style="text-decoration: none; filter: grayscale(1);" href="/posts?PostType=Revista">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/Revista.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Revista</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons">
                <a style="text-decoration: none; filter: grayscale(1);" href="/read-later-post">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/MasTarde.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">MasTarde</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons">
                <a style="text-decoration: none; filter: grayscale(1);" href="/favorite-post">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/Favoritos.png" height="40"/>
                </a>
                <label style="font-size: 0.85em;">Favoritos</label>
            </div>
            <div class="col MenuWidht mobil-menu-buttons">
                <a style="text-decoration: none; filter: grayscale(1);" href="/contact">
                    <img class="mx-auto d-block" src="/assets/img/sidebar/Contacto.png" height="40"/>
                </a>
                <label style="font-size: 0.8em;">Contacto</label>
            </div>
        </div>
    
    </div>
</div>



<!-- The Modal 1-->
<div class="modal fade" id="myModal">
    <div class="modal-dialog">
    <div class="modal-content modalborders">

        <!-- Modal Header -->
        <div class="modal-header">
            <div class="center-class">
                <img src="/assets/img/MDMCercaDeTi.png" width="45%"/>
                <!-- <h4 class="modal-title">Inicio de sesión</h4> -->
            </div>
            <div class="top-class">
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
        </div>

        <form [formGroup]="form">
            <div class="row" *ngIf="showMsg">
                <div class="col-xs-12">
                  <p class="alert alert-danger">
                      <strong>Usuario o Contraseña Incorrectos</strong>
                  </p>
                </div>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                    <label class="fira-sans">Usuario</label>
                    <input type="text" class="form-control inputborders" id="UserLogin" aria-describedby="emailHelp" placeholder="Ingrese su nombre de usuario" formControlName="username">
                    <div class="form-group contact-items" style="padding-top: 15px;">
                        <label class="fira-sans">Contraseña</label>
                        <input type="password" class="form-control inputborders" id="PasswordLogin" aria-describedby="emailHelp" placeholder="Ingrese su contraseña" formControlName="password">
                    </div>
                
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <div style="padding-left: 20px; padding-right: 20px; width:100%;">
                    <table  style="width:100%;">
                        <tr>
                            <td>
                                <button *ngIf="!userLogged" type="button" style="color: rgb(53,107,150);" class="btn btn-primary buttonaslink" data-bs-toggle="modal" data-bs-target="#myModal2">
                                    <strong class="login-text-size">Registrarse</strong>
                                </button>
                            </td>
                            <td style="text-align: right;">
                                <button type="submit" class="buttonaslink" (click)="login()">
                                    <img src="/assets/img/sidebar/login.png" height="40"/>
                                    <strong>Ingresar</strong>  
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="text-align: center; padding-top: 10px;">
                                <a href="/forgot-password" style="text-decoration: none; color: rgb(53,107,150);">
                                    <strong>¿Has olvidado tu contraseña?</strong>
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>
<!-- End Modal 1-->
                
<!-- The Modal 2-->
<div class="modal" id="myModal2">
    <div class="modal-dialog">
    <div class="modal-content modalborders">

        <!-- Modal Header -->
        <div class="modal-header">
            <div class="center-class">
                <img src="/assets/img/MDMCercaDeTi.png" style="width: 45%;"/>
            </div>
            <div class="top-class">
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
        </div>

        <form [formGroup]="formRegistration" autocomplete="off">
            <!-- Modal body -->
            <div class="modal-body">
                <div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                    <div class="">
                        <div class="row">
                            <div class="col-sm">
                                <label class="fira-sans">Nombre</label>
                                <input type="text" class="form-control inputborders" id="FirstNameRegister" placeholder="Ingrese nombre" formControlName="FirstName">
                                <div *ngIf="formRegistration.controls['FirstName'].invalid && (formRegistration.controls['FirstName'].dirty || formRegistration.controls['FirstName'].touched)">
                                    <div *ngIf="formRegistration.controls['FirstName'].errors.required">
                                        <label  class="text-danger">Campo requerido</label>
                                    </div>
                                </div> 
                            </div>
                            
                            <div class="col-sm">
                                <label class="fira-sans">Apellido</label>
                                <input type="text" class="form-control inputborders" id="LastNameRegister" placeholder="Ingrese apellido" formControlName="LastName">
                                <div *ngIf="formRegistration.controls['LastName'].invalid && (formRegistration.controls['LastName'].dirty || formRegistration.controls['LastName'].touched)">
                                    <div *ngIf="formRegistration.controls['LastName'].errors.required">
                                        <label  class="text-danger">Campo requerido</label>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <label class="fira-sans" style="padding-top: 15px;">Usuario</label>
                    <input type="text" class="form-control inputborders" id="UserRegister1" placeholder="Ingrese nombre de usuario" formControlName="UserName" required (change)="checkUserName()">
                    <div *ngIf="formRegistration.controls['UserName'].invalid && (formRegistration.controls['UserName'].dirty || formRegistration.controls['UserName'].touched)">
                        <div *ngIf="formRegistration.controls['UserName'].errors.required">
                            <label  class="text-danger">Campo requerido</label>
                        </div>
                        <div *ngIf="formRegistration.controls['UserName'].errors.notUnique">
                            <label  class="text-danger">Usuario no disponible</label>
                        </div>
                    </div> 
                    <label class="fira-sans" style="padding-top: 15px;">Contraseña</label>
                    <input type="password" class="form-control inputborders" id="PasswordRegister1" aria-describedby="emailHelp" placeholder="Ingrese contraseña" formControlName="Password">
                    <div *ngIf="formRegistration.controls['Password'].invalid && (formRegistration.controls['Password'].dirty || formRegistration.controls['Password'].touched)">
                        <div *ngIf="formRegistration.controls['Password'].errors.required">
                            <label  class="text-danger">Campo requerido</label>
                        </div>
                    </div> 
                    <label class="fira-sans" style="padding-top: 15px;">Email</label>
                    <input type="text" class="form-control inputborders" id="EmailRegister" placeholder="Ingrese correo electronico" formControlName="Email">
                    
                    
                    <div *ngIf="formRegistration.controls['Email'].invalid && (formRegistration.controls['Email'].dirty || formRegistration.controls['Email'].touched)">
                        <div *ngIf="formRegistration.controls['Email'].errors.required">
                            <label  class="text-danger">Campo requerido</label>
                        </div> 
                        <div *ngIf="formRegistration.controls['Email'].errors.pattern">
                            <label  class="text-danger">Email invalido</label>
                        </div> 
                    </div> 
                    
                    
                    
                    <label class="fira-sans" style="padding-top: 15px;">Pais</label>
                    <select class="form-control inputborders" id="CountryRegister" formControlName="Country" required [(ngModel)]="activeCountry" (ngModelChange)="getStates(activeCountry?.IdCountry)">
                        <option disabled selected>Seleccione un pais</option>
                        <option *ngFor="let item of CountryViewModel" [ngValue]="item">
                             {{item.DisplayName}}
                        </option>
                    </select>
                    <div *ngIf="formRegistration.controls['Country'].invalid && (formRegistration.controls['Country'].dirty || formRegistration.controls['Country'].touched)">
                        <div *ngIf="formRegistration.controls['Country'].errors.required">
                            <label  class="text-danger">Campo requerido</label>
                        </div>
                    </div> 
                    <label class="fira-sans" style="padding-top: 15px;">Estado, departamento o región</label>
                    <select class="form-control inputborders" id="StateRegister" formControlName="IdState" required>
                        <option disabled selected>Seleccione un estado</option>
                        <option *ngFor="let item of EstateCountry" [ngValue]="item.IdState">
                             {{item.StateName}}
                        </option>
                    </select>
                    <div *ngIf="formRegistration.controls['IdState'].invalid && (formRegistration.controls['IdState'].dirty || formRegistration.controls['IdState'].touched)">
                        <div *ngIf="formRegistration.controls['IdState'].errors.required">
                            <label  class="text-danger">Campo requerido</label>
                        </div>
                    </div> 
                </div>
                <app-privacy-notice [eeaCountry]="this.formRegistration.value.Country?.EEA"></app-privacy-notice>
                <div class="center-class">
                    <button type="button" class="btn btn-primary mb-2 contact-button-style buttonborders" (click)="register()">Registrarse</button>
                </div>
                
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <div style="padding-right: 20px;">
                    <button type="button" class="buttonaslink" data-bs-toggle="modal" data-bs-target="#myModal">
                        <img src="/assets/img/sidebar/login.png" height="40"/>
                        Ingresar  
                    </button>
                </div>
            </div>
        </form>
    </div>
    </div>
</div>
<!-- End Modal 2-->

<!-- The Modal 3-->
<div class="modal fade" id="myModal3">
    <div class="modal-dialog modal-lg">
    <div class="modal-content modalborders1">

        <!-- Modal Header -->
        <div class="modal-header">
            <div class="center-class">
                <img src="/assets/img/MDMCercaDeTi.png" width="45%"/>
                 
            </div>
            <div class="top-class">
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
        </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="center-class">
                    <h4 class="modal-title">Nuestra Mision</h4>
                </div>
                <br>
                <div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;" [innerHTML]= "configurationViewModel.Value"></div>
                <!--<div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                {{configurationViewModel.Value}}
                </div>-->
            </div>
            <!-- Modal footer -->
    </div>
    </div>
</div>
<!-- End Modal 3-->

