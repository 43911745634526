<div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center;">
    <div style="background-color: rgb(57, 155, 220); width: 90%; width: auto; border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 40px;">
        <p class="fira-sans-strong-mobil">Estudios Bíblicos</p>
    </div>
</div>

<div class="row cover" *ngIf="isMobile()"> 
    <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
        <div class="row right transparency" style="position: relative; z-index: 1;">
            <app-sidemenu ></app-sidemenu>
        </div>
    </div>
    <div class="vertical-class-text main-content" style="margin-bottom: -36px; display: flex;" [ngClass]="{'col-sm-5':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
        <div class="right-class">
            <p class="fira-sans-strong vertical-line-red">Estudios Bíblicos</p>
        </div>
    </div>
</div>

<div class="carrusel-main-container" *ngIf="isSuscriberAuthenticated && isMobile()">
    <div class="row" style="padding-left: 40px; padding-right: 40px;width: 100%;">
        <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
        <div class="carrusel-bible-container">
            <div class="carrusel">
                <button  style="color:rgb(53,107,150);" class="btn bnt-style" *ngFor="let tag of tagViewModel;" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu(), 'odditem':o}" (click)="changeTag(tag.Id)">
                    <div>
                        <img *ngIf=tag.Icon [src]="tag.Icon" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu(), 'odditem':o}" width="100%"/>
                    </div>
                </button>
            </div>
        </div>
        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
    </div>
</div>

<div *ngIf="isSuscriberAuthenticated && !isMobile()">
    <div>
        <div class="scroll" >
            <div class="slider-container" #myIdentifier id="containerpadre" [style.width.%] ='SliderContainerWidth'>  
                    <div class="containerhijo" style="padding:15px 10px 0px 10px; width: 15%;" *ngFor="let tag of tagViewModel; let o = odd" >
                        <button  class="btn bnt-style">
                            <a href="/bible-studies/{{tag.Id}}">
                                <div> 
                                    <img *ngIf=tag.Icon [src]="tag.Icon" [ngClass]="{'mobil-item':!isNotMobileMenu(), 'odditem':o}" style="width:115%; height:50px;"/>
                                </div>
                            </a>
                        </button>
                    </div>
                
            </div> 
        </div>
    </div> 
</div>

<div  *ngIf="load && isMobile()" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
    <div class="row" *ngFor="let row of bibleStudiesByPage.Rows">
        <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let bibleStudy of row.BibleStudies | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%;">
                    <div class="thumbnail">
                        <a *ngIf="bibleStudy.Content" class="fira-sans-link vertical-class left" href="/bible-study-read/{{bibleStudy.IdBibleStudie}}">
                            <img *ngIf="!showVideoLayout" [src]="bibleStudy.Image" style="width:100%; max-height: 200px;min-height: 200px;  ">
                        </a> 
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{bibleStudy.Title}}
                        </p>
                        <label>
                            {{bibleStudy.Introduction}}
                        </label>
                        <p><br></p>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancion">
                            <div class="col-8 col-sm-6 col-md-8" style="padding-bottom: 10px;">
                                <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px;">
                                    <a *ngIf="bibleStudy.Content" class="fira-sans-news" href="/bible-study-read/{{bibleStudy.IdBibleStudie}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-4 col-sm-6 col-md-4" style="padding-bottom: 10px;">
                                <div class="row" style="display: flex; justify-content: center;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class">
                                            <button class="buttonaslink" (click)="AddDeleteFavoriteStudy(bibleStudy.IdBibleStudie,bibleStudy.TagId)"><i [ngClass]="bibleStudy.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterStudy(bibleStudy.IdBibleStudie,bibleStudy.TagId)"><i [ngClass]="bibleStudy.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div class="input-group mb-3" style="padding-left: 20px; padding-right: 20px;" *ngIf="!isMobile()" >
    <span style="border-radius: 10px 0px 0px 10px; background: rgb(201,201,201);" class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
    <input type="text" style="border-radius: 0px 10px 10px 0px; background: rgb(201,201,201); color: white;" class="form-control" placeholder="Buscar" id="Serch" #identificador  autocomplete="off" (keyup)="SerchBibleStudy($event.target.value)">
</div>

<div  *ngIf="load && !isMobile()" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
    <div class="row" *ngFor="let row of bibleStudiesByPage.Rows">
        <div class="col-6" *ngFor="let bibleStudy of row.BibleStudies | paginate: { id: 'foo', itemsPerPage: 12, currentPage: page, totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%;">
                    <div class="thumbnail" style="padding: 10px 10px 0px 10px">
                        <a *ngIf="bibleStudy.Content" class="fira-sans-link vertical-class left" href="/bible-study-read/{{bibleStudy.IdBibleStudie}}">
                            <img *ngIf="!showVideoLayout" [src]="bibleStudy.Image" style="width:100%; height: 100px; border-radius: 15px;">
                            </a> 
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-titlemobil">
                            {{bibleStudy.Title}}
                        </p>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancionmobil">
                            <div class="col-12" style="padding-bottom: 0px; display: flex; justify-content: flex-end;">
                                <a *ngIf="bibleStudy.Content" class="fira-sans-newsmobil" href="/bible-study-read/{{bibleStudy.IdBibleStudie}}">Seguir leyendo</a>
                            </div>
                            <div class="col-12" style="padding-bottom: 0px;">
                                <div class="row" style="display: flex; justify-content: flex-end;">
                                    <div class="row star-content">
                                        <div class="col-12" style="display: flex; justify-content: flex-end;">
                                            <button class="buttonaslink" (click)="AddDeleteFavoriteStudy(bibleStudy.IdBibleStudie,bibleStudy.TagId)"><i [ngClass]="bibleStudy.IsStaredFavorite? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strongmobil">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterStudy(bibleStudy.IdBibleStudie,bibleStudy.TagId)"><i [ngClass]="bibleStudy.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strongmobil"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div class="row star-footer" *ngIf="isMobile()" >
    <div *ngIf="!isSuscriberAuthenticated" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
        <label><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
    </div>
    <div *ngIf="isSuscriberAuthenticated" class="col-xs-12 col-md-8 col-lg-6 star-footer-row">
        <div class="star-footer-items">
            <i class="bi bi-star-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post">&nbsp;&nbsp;Mis Favoritos</a>
            </i>
        </div>
        <div style="padding-left: 20px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong animation">
                <a style="text-decoration: none;" href="/read-later-post">&nbsp;&nbsp;Ver Más Tarde</a>
            </i>
        </div>
    </div>
</div>

<div *ngIf="!isMobile()" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
    <label *ngIf="!isSuscriberAuthenticated"><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
</div>

<div class="row star-footer1" *ngIf="!isMobile() && isSuscriberAuthenticated">
    <div class="star-footer-row-border-mobil"> 
    </div>
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row-mobil">
        <div>
            <i class="bi bi-star-fill fira-sans-news-blue strong-mobil animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post"></a>
            </i>
        </div>
        <div style="padding-left: 10px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong-mobil animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post"></a>
            </i>
        </div>
    </div>
    <div style="width: 15px;">
    </div>
</div>


    