<div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom: 10px;">
    <div style="background-color: rgb(57, 155, 220); width: auto; border-radius: 0px 0px 15px 15px; padding-left: 25px; padding-right: 25px; padding-top: 5px; height: 40px;">
        <p class="fira-sans-strong-mobil">Ver Más Tarde</p>
    </div>
</div>

<div class="row cover" *ngIf=isMobile()>
    <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
        <div class="row right transparency" style="position: relative; z-index: 1;">
            <app-sidemenu></app-sidemenu>
        </div>
    </div>
    <div *ngIf="!showVideoLayout" class="vertical-class-text main-content" [ngClass]="{'col-sm-4':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
        <div class="right-class">
            <p class="fira-sans-strong vertical-line-red">Ver Más Tarde</p>
        </div>
    </div>
</div>

<div class="row coverMobil" *ngIf=!isMobile() style="border-radius: 25px;">
    <div *ngIf="!showVideoLayout" class="vertical-class-text main-content">
    </div>
</div>

<div class="container" style="padding-top: 15px; display: flex; justify-content: center;">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <button class="nav-link fira-sans titlesize" (click)="ShowPosts()">Artículos</button>
        </li>
        <li class="nav-item">
            <button class="nav-link fira-sans titlesize" (click)="ShowStudies()">Estudios Biblicos</button>  
        </li>
      </ul>
</div>

<div *ngIf="!isSuscriberAuthenticated" class="row cover-text" style="background-color: rgb(245, 245, 245); text-align: center; padding-top: 15px; padding-bottom: 20px;">
    <label><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
</div>
<app-read-later-post-list id="Posts"></app-read-later-post-list>
<app-read-later-bible-study-list id="Studies" style="display: none;"></app-read-later-bible-study-list>

<div class="row star-footer">
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row">
        <div class="star-footer-items">
            <i class="bi bi-star-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post">&nbsp;&nbsp;Mis Favoritos</a>
            </i>
        </div>
        <div style="padding-left: 20px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post">&nbsp;&nbsp;Ver Más Tarde</a>
            </i>
        </div>
    </div>
</div>
