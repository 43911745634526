<div class="main-profecy-container" *ngIf=isMobile()>
    <div class="row header-profecy-container">
        <table *ngIf=!isNotMobileMenu()> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Noticias y Profecía</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Noticias" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row" *ngIf=isNotMobileMenu()> 
            <div class="header-profecy-container-margin col-sm-4">
                <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Noticias y Profecía</label>
            </div>
            <div class="col-sm-3 look-more">
                <a href="/posts?PostType=Noticias" >
                    <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="isNotMobileMenu()" class="carrusel-main-container">
        <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
        <div class="carrusel-container">
            <div class="carrusel">
                <div class="row" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}" *ngFor="let post of mainProfecyPostViewModel">
                    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-6">
                        <div class="thumbnail">
                            <a class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">
                                <img [src]="post.URLImage" alt="Lights" style="width:100%">
                            </a>
                        </div>
                    </div>
                    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-6">
                        <p class="fira-sans-news strong">{{post.Title}}</p>
                        <P class="fira-sans-news">{{post.Review}}</P>
                        <div class="row alieancion" style="justify-content: flex-end;">
                            <div style="padding-bottom: 10px; width: auto;"> 
                                <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                            </div>
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
    </div>
    <br *ngIf="!isNotMobileMenu()" >
    <div *ngIf="!isNotMobileMenu()" id="carouselIndicatorsProfecy" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
        
        <div class="carousel-indicators" style="padding-bottom: 0px;">
            <span>
                <button type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide-to="0" class="active"></button>
            </span>
            <span *ngFor ="let post of mainProfecyPostViewModel | slice:1; index as i;">
                <button type="button" data-bs-target="#carouselIndicatorsProfecy"   [attr.data-bs-slide-to]="i+1"></button>
            </span>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div *ngIf="mainProfecyPostViewModel !=null" class="mobil-item">
                    <div>
                        <a href="/post/{{mainProfecyPostViewModel[0].IdPost}}">
                            <img class="imagen"  [src]="mainProfecyPostViewModel[0].URLImage">
                        </a>
                        <p class="fira-sans-news-title">{{mainProfecyPostViewModel[0].Title}}</p>
                        <p>{{mainProfecyPostViewModel[0].Review}}</p>
                    </div>
                    <div class="row alieancion" style="padding-bottom: 25px;">
                        <div class="col-sm-12 vertical-class left">
                                <a *ngIf="mainProfecyPostViewModel[0].Content" style="padding-right: 0px;" href="/post/{{mainProfecyPostViewModel[0].IdPost}}">
                                    Seguir leyendo
                                </a> 
                        </div>
                    </div>
                </div>               
            </div>
            <div class="carousel-item" *ngFor="let post of mainProfecyPostViewModel | slice:1; let i = index">
                <div class="mobil-item">
                    <a href="/post/{{post.IdPost}}">
                        <img class="imagen"  [src]="post.URLImage">
                    </a>
                    <p class="fira-sans-news-title">{{post.Title}}</p>
                    <p>{{post.Review}}</p> 
                    <div class="row alieancion" style="padding-bottom: 25px;">
                        <div class="col-sm-12 vertical-class left">
                            <button type="button"  class="fira-sans-news" style="border: none; background: none;">
                                <a *ngIf="post.Content" style="padding-right: 0px;" href="/post/{{post.IdPost}}">
                                    <label>Seguir leyendo</label> 
                                </a>
                            </button>
                        </div>
                    </div>   
                </div>
                
            </div>
          
        </div>

    </div>
</div>

<div class="main-profecy-container" *ngIf=!isMobile()>
    <div class="row header-profecy-container">
        <table> 
            <tr>
                <td>
                    <div class="header-profecy-container-margin">
                        <label [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Noticias y Profecía</label>
                    </div>
                </td>
                <td style="padding-top: 10px;"> 
                    <div class="look-more">
                        <a href="/posts?PostType=Noticias" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div>
            <div>
                <div class="scroll">
                    <div class="slider-container">  
                        <div style="padding:5px 10px 0px 10px; display: grid;" *ngFor="let post of mainProfecyPostViewModel">
                            <a class="fira-sans-news vertical-class left" style="display: flex; align-items: flex-start;" href="/post/{{post.IdPost}}">
                                <img class="slider-item" [src]="post.URLImage">
                            </a>
                            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-6" style="padding-top: 10px; display: flex; flex-direction: column;">
                                <p class="fira-sans-news strong" >{{post.Title}}</p>                                  
                            </div>
                            <div class="row" style="display: flex; justify-content: flex-end; align-content: flex-end; padding-right: 15px;">
                                <div style="padding-bottom: 0px; width: auto;">
                                    <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 
        </div>

    </div>
</div>
