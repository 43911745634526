import { SafeResourceUrl } from '@angular/platform-browser';
export class MainCoverPost {
    IdPost: number;
    Title: string;
    Review: string;
    URLImage: string;
    URLVideo: string;
    URLVideoBackUp: string;
    URLSafeVideo: SafeResourceUrl;
    URLSafeImage: SafeResourceUrl;
    Content: string;
    Enabled: boolean;
    IdPostType: number;
    TimeToRead: string;
    IsStaredFavorite:boolean;
    IsStaredRead:boolean;
    YTCensored:boolean;
}
