import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PostByPageViewModel} from '../models/post-by-page-view-model';
import { MainCoverPost} from '../models/main-cover-post';

import { ActivatedRoute } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';

declare const $: any;
@Component({
  selector: 'app-main-post-list',
  templateUrl: './main-post-list.component.html',
  styleUrls: ['./main-post-list.component.css']
})

export class MainPostListComponent implements OnInit {
  postByPage: PostByPageViewModel;
  load : boolean = false;
  firstVideoLoad : boolean = false;

  favoritePostCheked: boolean = false;
  isSuscriberAuthenticated : boolean = false;
  totalItems : number = 15;
  page : number = 1;
  videoId : number = 1;
  MainVideo : MainCoverPost;
  postType :string ="";
  title :string ="";
  titlemobil :string ="";
  rowItems : number = 3;
  
  showVideoLayout : boolean = false;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private activateroute:ActivatedRoute) { }

  ngOnInit(): void {
    this.activateroute.queryParams.subscribe(params => {
      this.postType = params['PostType'];
      this.videoId = params['VideoId'];
    });
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
    this.getVideoValue();

    if(this.videoId != undefined)
    {
      this.getMainVideo();
    }

    this.setTitle();
    this.getPostByPage();
  }

  getMainVideo()
  {
    this.api.get("/api/post/"+this.videoId).subscribe((response: any) => {
      this.MainVideo = response;
      this.firstVideoLoad = true;
      if(this.MainVideo.YTCensored == false)
      {
        this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.MainVideo.URLVideo:this.MainVideo.URLVideoBackUp);
      }
      else if (this.MainVideo.YTCensored == true)
      {
        this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.MainVideo.URLVideo);
      }
    });
  }

  getPostByPage()
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }

    if(this.isSuscriberAuthenticated)
    {
      this.api.getWithAuthentication("/api/mainpostsList/"+this.postType+"/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
        this.postByPage = response;
        this.totalItems = this.postByPage.TotalRecords;
        this.load = true;
        this.postByPage.Rows.forEach(element => {
          element.Post.forEach(post => {
            post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.URLImage);
            
            if(post.YTCensored == false)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.URLVideo:post.URLVideoBackUp);
            }
            else if (post.YTCensored == true)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(post.URLVideo);
            }
          });
        });
        if(this.videoId ==undefined)
        {
          this.MainVideo = this.postByPage.Rows[0].Post[0];
         
          if(this.MainVideo.YTCensored == false)
          {
            this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.MainVideo.URLVideo:this.MainVideo.URLVideoBackUp);
          }
          else if (this.MainVideo.YTCensored == true)
          {
            this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.MainVideo.URLVideo);
          }
        }
        this.firstVideoLoad = true;
        }
      )
    }
    else
    {
      this.api.get("/api/mainpostsList/"+this.postType+"/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
        this.postByPage = response;
        this.totalItems = this.postByPage.TotalRecords;
        this.load = true;
        this.postByPage.Rows.forEach(element => {
          element.Post.forEach(post => {
            post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.URLImage);
            if(post.YTCensored == false)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.URLVideo:post.URLVideoBackUp);
            }
            else if (post.YTCensored == true)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(post.URLVideo);
            }
          });
        });
        if(this.videoId ==undefined)
        {
          this.MainVideo = this.postByPage.Rows[0].Post[0];
          if(this.MainVideo.YTCensored == false)
          {
            this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.MainVideo.URLVideo:this.MainVideo.URLVideoBackUp);
          }
          else if (this.MainVideo.YTCensored == true)
          {
            this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.MainVideo.URLVideo);
          }
        }
        this.firstVideoLoad = true;
        }
      )
    }
  }

  setTitle(){
    switch(this.postType)
    {
      case "Noticias":
        this.title = "Noticias y Profecía";
        this.titlemobil = "Noticias y Profecía";
        break;
      case "Revista":
        this.title = "";
        this.titlemobil = "Revista El Mundo de Mañana";
        break;
      case "Enfoque":
        this.title = "";
        this.titlemobil = "Un Nuevo Enfoque";
        this.showVideoLayout = true;
        break;
      case "Estudios":
        this.title = "Estudios Bíblicos"
        break;
      case "Shorts":
        this.title = "Shorts"
        this.titlemobil = "Shorts";
        this.showVideoLayout = true;
        break;
      case "Preguntas":
          this.title = "Preguntas y respuestas"
          break;
      default:
        this.title = ""
        break;
    }
  }
 
  loadPage(page: number) {
    this.page =1 ;
  }

  handlePageChange(event) {
    this.page = event;
    this.getPostByPage();
    window.scroll(0,0)
  }
  
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }

  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    return false;
  }

  getVideoValue()
  {
    this.api.get("/Configuration?key=Video").subscribe((response: any) => {
      this.configurationViewModel = response
      }
    )
  }

  AddDeleteFavoritePost(IdPost:number)
  {
    var autenticad=this.api.isAuthenticated();

    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.favoritePostCheked=true;

      this.api.postWithAuthentication("/api/favoritepost/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        });
      }
  }

  AddDeleteReadLaterPost(IdPost:number)
  {
    var autenticad=this.api.isAuthenticated();

    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/readlaterpost/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        })
    }
  }
}
