<div class="container-text" *ngIf="isMobile()">
    <div *ngIf="isNotMobileMenu()">

    </div>
    <div class="row cover-text">
        <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
            <div class="row right">
                <app-sidemenu></app-sidemenu>
            </div>
            <div class="row">
                <div class="row right">
                    <div class="col-sm-5 fira-sans-news-blue strong" style="width: 85%;">
                        
                        <p style="margin-top: 100px;">
                            {{mainPostViewModel.Review}}
                        </p>
                        <div class="row" style="display: flex;">
                            <div class="row star-content">
                                <div class="col-sm-4">
                                    <button class="buttonaslink" (click)="AddDeleteFavoritePost(mainPostViewModel.IdPost)"><i [ngClass]="mainPostViewModel.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                </div>
                                <div class="col-sm-4">
                                    <button class="buttonaslink" (click)="AddDeleteReadLaterPost(mainPostViewModel.IdPost)"><i [ngClass]="mainPostViewModel.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                   
                </div>
            </div>
        </div>
        <div class="vertical-class-text main-content" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div class="youtube-video-container" *ngIf="mainPostViewModel.URLVideo">
                <iframe *ngIf="configurationViewModel.Value == 'YouTube' && mainPostViewModel.YTCensored == false" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                <video *ngIf="configurationViewModel.Value == 'MediaFire' && mainPostViewModel.YTCensored == false" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                <video *ngIf="mainPostViewModel.YTCensored" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
            </div>
            <div class="youtube-video-container" *ngIf="ImageURL() && !mainPostViewModel.URLVideo">
                <img [src]="ImageURL()" alt="Lights" style="width:100%; max-width: 1296px; max-height: 600px;">
            </div>
            <br>  
            <p> 
                <strong>
                    <h4>
                        {{mainPostViewModel.Title}}
                    </h4>
                </strong>
            </p>
            <p class="main-content-opacy"> 
                Tiempo de lectura:
                        {{mainPostViewModel.TimeToRead}}
            </p>
                
               <div class="main-content-opacy" [innerHTML]= "mainPostViewModel.Content"></div>
        </div>
    </div>
</div>

<div class="container-text" *ngIf="!isMobile()">
    <div *ngIf="isNotMobileMenu()">

    </div>
    <div class="row cover-text">
        <div class="vertical-class-text" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div class="youtube-video-container" *ngIf="mainPostViewModel.URLVideo">
                <iframe class="corner-wrapper" *ngIf="configurationViewModel.Value == 'YouTube' && mainPostViewModel.YTCensored == false" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                <video class="imagen" *ngIf="configurationViewModel.Value == 'MediaFire' && mainPostViewModel.YTCensored == false" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                <video class="imagen" *ngIf="mainPostViewModel.YTCensored" width="838" height="470" [src]='mainPostViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
            </div>
            <div class="youtube-video-container" *ngIf="ImageURL() && !mainPostViewModel.URLVideo">
                <img class="imagen" [src]="ImageURL()" alt="Lights">
            </div>
            <br>  
            <div class="main-content">
                <p class="vertical-line-red"> 
                    <strong>
                        <h4>
                            {{mainPostViewModel.Title}}
                        </h4>
                    </strong>
                </p>
                <p class="main-content-opacy"> 
                    Tiempo de lectura:
                            {{mainPostViewModel.TimeToRead}}
                </p>
                <div style="/*overflow: auto;*/" class="main-content-opacy" [innerHTML]= "mainPostViewModel.Content"></div>  
            </div>
        </div>
    </div>
</div>

<div class="row star-footer" *ngIf="isMobile()">
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row">
        <div class="star-footer-items">
            <i class="bi bi-star-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post">&nbsp;&nbsp;Mis Favoritos</a>
            </i>
        </div>
        <div style="padding-left: 20px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post">&nbsp;&nbsp;Ver Más Tarde</a>
            </i>
        </div>
    </div>
</div>

<div class="row star-footer1" *ngIf="!isMobile()">
    <div class="star-footer-row-border-mobil"> 
    </div>
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row-mobil">
        <div>
            <a class="animation" style="text-decoration: none;" href="/favorite-post">
                <i class="bi bi-star-fill fira-sans-news-blue strong-mobil animation"></i>
            </a>
        </div>
        <div style="padding-left: 10px;">
            <a class="animation" style="text-decoration: none;" href="/read-later-post">
                <i class="bi bi-bookmark-fill fira-sans-news-blue strong-mobil animation"></i>
            </a>
        </div>

    </div>
    <div style="width: 15px;">

    </div>
</div>


