<div *ngIf="isMobile()" class="row coverNyA" [ngClass]="{'coverNyA':postType=='Noticias','coverRMDM':postType=='Revista','coverUNF':postType=='Enfoque'}">
    
        <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
            <div class="row right transparency" style="position: relative; z-index: 1;">
                <app-sidemenu></app-sidemenu>
            </div>
        </div>
        <div class="vertical-class-text main-content" style="align-items: flex-start; margin-top: 35px;" [ngClass]="{'col-sm-5':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div class="right-class">
                <p class="fira-sans-strong vertical-line-red">{{title}}</p>
            </div>
        </div>
        <div *ngIf="!showVideoLayout && firstVideoLoad" class="vertical-class-text" [ngClass]="{'col-sm-8':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()" style="padding-left:6%;padding-right: 6.5%;">
        </div>
</div>
<div  *ngIf="load && isMobile() && postType!='Shorts'" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%;">
                    <div class="thumbnail">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}"><img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; max-height: 200px;min-height: 200px;  "></a>
                        
                        <div *ngIf="showVideoLayout" class="youtube-video-container">  
                            <iframe *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{post.Title}}
                        </p>
                        <label>
                            {{post.Review}}
                        </label>
                        <p><br></p>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancion">
                            <div class="col-8 col-sm-6 col-md-8" style="padding-bottom: 10px;">
                                <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px;" *ngIf="!post.showVideoLayout">
                                    <a *ngIf="post.Content" class="fira-sans-news" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-4 col-sm-6 col-md-4" style="padding-bottom: 10px;">
                                <div class="row" style="display: flex; justify-content: center;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div  *ngIf="load && isMobile() && postType=='Shorts'" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%;">
                    <div class="thumbnail">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}"><img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; max-height: 200px;min-height: 200px;  "></a>
                        
                        <div *ngIf="showVideoLayout">  
                            <iframe *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video style="width: 100%; height: 100%;" *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video style="width: 100%; height: 100%;" *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{post.Title}}
                        </p>
                        <label>
                            {{post.Review}}
                        </label>
                        <p><br></p>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancion">
                            <div class="col-8 col-sm-6 col-md-8" style="padding-bottom: 10px;">
                                <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px;" *ngIf="!post.showVideoLayout">
                                    <a *ngIf="post.Content" class="fira-sans-news" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-4 col-sm-6 col-md-4" style="padding-bottom: 10px;">
                                <div class="row" style="display: flex; justify-content: center;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div  *ngIf="load && !isMobile() && postType!='Enfoque' && postType!='Shorts'" class="row fira-sans PaddingArti" style="background-color: rgb(221, 221, 221);">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div class="dinamic-height"style="background-color: rgb(218, 35, 57); width: 90%; width: auto; border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px;">
            <p class="fira-sans-strong-mobil">{{titlemobil}}</p>
        </div>
    </div>
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-6" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%; border-radius: 15px;">
                    <div class="thumbnail" style="padding: 10px 10px 0px 10px;">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}">
                            <img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; height: 100px; border-radius: 15px;">
                        </a>
                    </div>
                    <div class="grid-item-text">
                        <p class="fira-sans-news-titlemobil">
                            {{post.Title}}
                        </p>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancionmobil">
                            <div class="col-12" style="padding-bottom: 0px; display: flex; justify-content: flex-end;">
                                <a *ngIf="post.Content" class="fira-sans-newsmobil" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                            </div>
                            <div class="col-12" style="padding-bottom: 0px;">
                                <div class="row" style="display: flex; justify-content: flex-end;">
                                    <div class="row star-content">
                                        <div class="col-12" style="display: flex; justify-content: flex-end;">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strongmobil">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strongmobil"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div  *ngIf="load && !isMobile() && postType=='Enfoque'" class="row fira-sans PaddingArti" style="background-color: rgb(214, 214, 214);">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div style="background-color: rgb(218, 35, 57); width: 90%; width: auto; border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 40px;">
            <p class="fira-sans-strong-mobil">{{titlemobil}}</p>
        </div>
    </div>
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%; border-radius: 15px; padding: 25px;">
                    <div class="thumbnail">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}"><img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; max-height: 200px;min-height: 200px;  "></a>
                        
                        <div *ngIf="showVideoLayout" class="youtube-video-container">
                            <iframe class="videoradio" *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video class="videoradio" *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video class="videoradio" *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{post.Title}}
                        </p>
                        <label>
                            {{post.Review}}
                        </label>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancion">
                            <div class="col-5 col-sm-6 col-md-8" style="padding-bottom: 10px;">
                                <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px;" *ngIf="!post.showVideoLayout">
                                    <a *ngIf="post.Content" class="fira-sans-news" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-7 col-sm-6 col-md-4" style="padding-bottom: 10px;">
                                <div class="row" style="display: flex; justify-content: center;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class-mobil">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div  *ngIf="load && !isMobile() && postType=='Shorts'" class="row fira-sans PaddingArti" style="background-color: rgb(214, 214, 214);">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div style="background-color: rgb(218, 35, 57); width: 90%; width: auto; border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 40px;">
            <p class="fira-sans-strong-mobil">{{titlemobil}}</p>
        </div>
    </div>
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%; border-radius: 15px; padding: 25px;">
                    <div class="thumbnail">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}"><img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; max-height: 200px;min-height: 200px;  "></a>
                        
                        <div *ngIf="showVideoLayout" >
                            <iframe class="videoradio" *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video style="width: 100%; height: 100%;" class="videoradio" *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video style="width: 100%; height: 100%;" class="videoradio" *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{post.Title}}
                        </p>
                        <label>
                            {{post.Review}}
                        </label>
                    </div>
                    <div class="row" style="display: inline-block;">
                        <div class="row star-content alieancion">
                            <div class="col-5 col-sm-6 col-md-8" style="padding-bottom: 10px;">
                                <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px;" *ngIf="!post.showVideoLayout">
                                    <a *ngIf="post.Content" class="fira-sans-news" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-7 col-sm-6 col-md-4" style="padding-bottom: 10px;">
                                <div class="row" style="display: flex; justify-content: center;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class-mobil">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>   
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
                              responsive="true"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
</div>

<div class="row star-footer" *ngIf="isMobile()">
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row">
        <div class="star-footer-items">
            <i class="bi bi-star-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post">&nbsp;&nbsp;Mis Favoritos</a>
            </i>
        </div>
        <div style="padding-left: 20px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post">&nbsp;&nbsp;Ver Más Tarde</a>
            </i>
        </div>
    </div>
</div>

<div class="row star-footer1" *ngIf="!isMobile()">
    <div class="star-footer-row-border-mobil"> 
    </div>
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row-mobil">
        <div>
            <a class="animation" style="text-decoration: none;" href="/favorite-post">
                <i class="bi bi-star-fill fira-sans-news-blue strong-mobil animation"></i>
            </a>
        </div>
        <div style="padding-left: 10px;">
            <a class="animation" style="text-decoration: none;" href="/read-later-post">
                <i class="bi bi-bookmark-fill fira-sans-news-blue strong-mobil animation"></i>
            </a>
        </div>

    </div>
    <div style="width: 15px;">

    </div>
</div>