import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PostByPageViewModel} from '../models/post-by-page-view-model';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';
import { BibleStudyByPageViewModel } from '../models/bible-study-by-page-view-model';
declare const $: any;

@Component({
  selector: 'app-favorite-bible-study-list',
  templateUrl: './favorite-bible-study-list.component.html',
  styleUrls: ['./favorite-bible-study-list.component.css']
})
export class FavoriteBibleStudyListComponent implements OnInit {
  postByPage: PostByPageViewModel;
  bibleLoad : boolean = false;
  firstVideoLoad : boolean = false;
  bibleTotalItems : number = 15;
  biblePage : number = 1;
  videoId : number = 1;
  bibleStudiesByPage: BibleStudyByPageViewModel;
  postType :string ="";
  title :string ="";
  showVideoLayout : boolean = false;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  rowItems : number = 3;

  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private activateroute:ActivatedRoute, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.activateroute.queryParams.subscribe(params => {
      this.postType = params['PostType'];
      this.videoId = params['VideoId'];
    });
    this.getVideoValue();

    $(() => {
      var autenticad=this.api.isAuthenticated();
      if(autenticad == false)
      {
        $('#myModal').modal('toggle')
      }
      else{
        this.getPostByPage();
      }
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  getPostByPage()
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }
    this.api.getWithAuthentication("/api/favoritestudy/"+this.biblePage+"/"+this.rowItems).subscribe((response: any) => {
      this.bibleStudiesByPage = response;
      this.bibleTotalItems = this.bibleStudiesByPage.TotalRecords;
      this.bibleLoad = true;
      this.bibleStudiesByPage.Rows.forEach(element => {
        element.BibleStudies .forEach(post => {
          post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.Image);
          post.URLSafeVideo= this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.UrlVideo:post.UrlVideoBk);
        });
      });
      this.bibleLoad = true;
      this.firstVideoLoad = true;
      console.log(this.bibleStudiesByPage)
      this.cdRef.detectChanges();
      }
    )
  }

  handleBiblePageChange(event) {
    this.biblePage = event;
    this.bibleLoad = false;
    this.getPostByPage();
    window.scroll(0,0)
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }
  
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    return false;
  }

  getVideoValue()
  {
    this.api.get("/Configuration?key=Video").subscribe((response: any) => {
      this.configurationViewModel = response
      }
    )
  }
  AddDeleteFavoriteStudy(IdPost:number){
    var autenticad=this.api.isAuthenticated();
    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/favoriteStudies/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        });
      }
  }
  AddDeleteReadLaterStudy(IdPost:number){
    var autenticad=this.api.isAuthenticated();
    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/readlaterstudy/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        });
    }
  }
}
