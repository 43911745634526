import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
declare const $: any;

@Component({
  selector: 'app-read-later-list',
  templateUrl: './read-later-list.component.html',
  styleUrls: ['./read-later-list.component.css']
})
export class ReadLaterListComponent implements OnInit {
  showVideoLayout : boolean = false;
  isSuscriberAuthenticated : boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
    if(this.isSuscriberAuthenticated)
    {
      this.ShowPosts()
    }
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    
    return false;
  };
  ShowPosts() {
    document.getElementById("Posts").style.display = "block";;
    document.getElementById("Studies").style.display = "none";;
  }
  ShowStudies() {
    document.getElementById("Posts").style.display = "none";;
    document.getElementById("Studies").style.display = "block";
  }
}
