<div class="center-class vertical-class">
    <div class="sidebar border-light">
        <div class="sidebar_elements">         
            <ul class="list-group list-group-flush">
                <a href="/posts?PostType=Noticias" class="list-group-item list-group-item-action">
                    <img src="/assets/img/sidebar/Profecia.png" height="40"/>
                    Noticias y Profecía
                </a>
            </ul>
            <ul class="list-group list-group-flush">
                <a href="/posts?PostType=Revista" class="list-group-item list-group-item-action">
                    <img src="/assets/img/sidebar/Revista.png" height="40"/>
                    Revista
                </a>
            </ul>
            <ul class="list-group list-group-flush">
                <a href="/posts?PostType=Enfoque" class="list-group-item list-group-item-action">
                    <img src="/assets/img/sidebar/NuevoEnfoque.png" height="40"/>
                    Un Nuevo Enfoque
                </a>
            </ul>
            <ul class="list-group list-group-flush">
                <a href="/bible-studies" class="list-group-item list-group-item-action">
                    <img src="/assets/img/sidebar/LeerBiblia.png" height="40"/>
                    Estudios Bíblicos
                </a>
            </ul>
            <ul class="list-group list-group-flush">
                <a href="/bible" class="list-group-item list-group-item-action">
                    <img src="/assets/img/sidebar/Biblia.png" height="40"/>
                    Leer la Biblia
                </a>
            </ul>
            <div class="row">
                <ul class="dropdown">
                        <button class="dropbtn"><i class="bi bi-list" height="50"></i></button>
                        <div class="row">
                            <div class="dropdown-content dropdown-menu-animated">
                                <ul class="list-group list-group-flush">
                                    <ul class="list-group list-group-flush">
                                        <a href="#myModal3" class="list-group-item list-group-item-action" data-bs-toggle="modal" data-target="#myModal3">
                                            <img src="/assets/img/sidebar/Mision.png" height="40"/>
                                            Nuestra Misión
                                        </a>
                                    </ul>
                                    <ul class="list-group list-group-flush">
                                        <a href="https://www.elmundodemanana.org/comunicarse/donaciones" class="list-group-item list-group-item-action">
                                            <img src="/assets/img/sidebar/donasionesicon.png" height="40"/>
                                            Donaciones
                                        </a>
                                    </ul>
                                    <ul class="list-group list-group-flush">
                                        <a href="/favorite-post" class="list-group-item list-group-item-action">
                                            <img src="/assets/img/sidebar/Favoritos.png" height="40"/>
                                            &nbsp;&nbsp;&nbsp;Mis Favoritos
                                        </a>  
                                    </ul>
                                    <ul class="list-group list-group-flush">
                                        <a href="/read-later-post" class="list-group-item list-group-item-action">
                                            <img src="/assets/img/sidebar/MasTarde.png" height="40"/>
                                            &nbsp;&nbsp;&nbsp;Ver Más Tarde
                                        </a>  
                                    </ul>                               
                                </ul>

                            </div>
                        </div>
                </ul>
            </div>
        </div>
    </div>
</div>



