<div  *ngIf="!isMobile()" class="row fira-sans PaddingArti">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div style="background-color: rgb(41, 67, 116); width: 65%;  border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 42px;">
            <p class="fira-sans-strong-mobil">Plan de lectura</p>
        </div>
    </div>
</div>
<div class="row cover-text">
    <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
        <div class="row right">
            <app-sidemenu></app-sidemenu>
        </div>
    </div>
 
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div class="">
                <img class="dailyversecss" [src]='ImageURL()'>
            </div>
        </div>
    </div>   
</div>

<div class="row" *ngIf="isNotMobileMenu()" style="padding-top: 30px;">
</div>

<div *ngIf="!isSuscriberAuthenticated" class="row cover-text" style="background-color: rgb(245, 245, 245); text-align: center; padding-top: 15px; padding-bottom: 20px;">
    <label><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
</div>

<div *ngIf="isSuscriberAuthenticated && isMobile()" class="row cover-text">
    <div [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="row right" style="text-align: center;" *ngIf="!biblereadcurrentday.PlanStatus">
            <strong>
                <div style="  display: flex; align-items: center; margin: 0 auto; width: 75px; height: 10px; margin-top: 0.95rem; border-top: 3px solid red;">
                    <p style="padding-left: 5px; text-align: center;  vertical-align: text-top; font-size: 40px; color: rgb(53,107,150); margin-bottom: -2.05rem;">
                        Día
                    </p>
                </div>
                <p *ngIf="10 > biblereadcurrentday.CurrentDay" style="font-size: 95px; color: red; text-align: center; padding-left: 50px;">
                     0{{biblereadcurrentday.CurrentDay}}
                </p>
                <p *ngIf="biblereadcurrentday.CurrentDay >= 10" style="font-size: 95px; color: red; text-align: center; padding-left: 50px;">
                    {{biblereadcurrentday.CurrentDay}}
               </p>
            </strong>
        </div>
        <div *ngIf="isNotMobileMenu()"   style="padding-top: 150px;">
        </div>
        <div class="row right" style="text-align: center; width: 85%; display: block; margin: auto;">
            <a href='/bible-read-plan-progress'>
                <img src='/assets/img/MyProgress.png' class="imagenwidth">
            </a>
        </div>
    </div>
 
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" *ngIf="!biblereadcurrentday.PlanStatus">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div id="titlechapter">
                <div id="carouselIndicatorsProfecy" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
                    <ol class="carousel-indicators" style="padding-bottom: 0px;">
                        <li data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" data-bs-slide-to="0" class="active"></li>
                        <span *ngFor ="let post of biblereadcurrentday.BibleReadDayChapters | slice:1; index as i;">
                            <li data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" [attr.data-bs-slide-to]="i+1"></li>
                        </span>
                    </ol>

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div *ngIf="biblereadcurrentday.BibleReadDayChapters !=null" class="mobil-item">
                                <div class="row" *ngIf="biblereadcurrentday.CurrentDay == 1" style="padding-top: 30px;">
                                    <p class="fira-sans-news-title" style="color: rgb(53,107,150);">Introducción</p>
                                    <p>Estos, pues, son los mandamientos, estatutos y decretos que el Eterno vuestro Dios mandó que os enseñase, para que los pongáis por obra… Guardando todos sus estatutos y sus mandamientos que yo te mando, tú, tu hijo, y el hijo de tu hijo, todos los días de tu vida, para que tus días sean prolongados.  (Deuteronomio 6:1-2)</p>
                                </div>

                                <div class="row" style="background-color: white; z-index: 1; position:relative;">
                                     <p class="fira-sans-news-title" style="color: rgb(53,107,150);">{{biblereadcurrentday.BibleReadDayChapters[0].Title}}</p>
                                </div>
                                <div style="margin-top: -55px;">
                                    <p [innerHTML]= "_sanitizer.bypassSecurityTrustHtml(biblereadcurrentday.BibleReadDayChapters[0].Chapter)"></p>
                                </div>
                                <button class="btn btn-primary btn-lg BuscarColor" (click)= "CompleteCurrentDay(biblereadcurrentday.CurrentDay,biblereadcurrentday.IdBibleReadPlan)" *ngIf="biblereadcurrentday.BibleReadDayChapters.length==1">Completar</button>
                            </div>  
                        </div>

                        <div class="carousel-item" *ngFor="let post of biblereadcurrentday.BibleReadDayChapters | slice:1; let i = index">
                            <div class="mobil-item">
                                <div style="background-color: white; z-index: 1;position:relative; ">
                                    <p class="fira-sans-news-title" style="color: rgb(53,107,150);">{{post.Title}}</p>
                                </div>
                                    <div style="margin-top: -55px;">
                                        <p [innerHTML]= "_sanitizer.bypassSecurityTrustHtml(post.Chapter)"></p>
                                    </div>

                                <button class="btn btn-primary btn-lg BuscarColor" (click)= "CompleteCurrentDay(biblereadcurrentday.CurrentDay,biblereadcurrentday.IdBibleReadPlan)" *ngIf="i+2==biblereadcurrentday.NoChapters">Completar</button>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                      <!-- Left and right controls/icons -->
                    <div *ngIf="biblereadcurrentday.NoChapters > 1" style="position: relative; bottom: 5px;  left: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="prev">
                            <span style="background-color: black; border-radius: 25px;" class="carousel-control-prev-icon"></span>&nbsp;Atras
                          </button>
                    </div>
                    <div *ngIf="biblereadcurrentday.NoChapters > 1" style="position: relative; bottom: 5px;  right: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-next" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="next">
                            Siguiente &nbsp;<span style="background-color: black; border-radius: 25px;" class="carousel-control-next-icon"></span>
                          </button>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-2" style="display: flex; align-items: center; justify-content: center;">
                    <a href="http://biblia.com/"><img src="http://api.biblia.com/v1/PoweredByBiblia.png" alt="Powered by Biblia" /></a>
                </div>
                <div class="col-sm-10" style="display: flex; align-items: center; justify-content: center; padding-left: 5px;">
                    <p  class="main-content-opacy">Texto bíblico Reina-Valera 1960® © Sociedades Bíblicas en América Latina, 1960. Derechos renovados 1988, Utilizado con permiso Reina-Valera 1960 ® es una marca registrada de las Sociedades Bíblicas
                        Unidas y puede ser usada solo bajo licencia.
                    </p>
                </div>
            </div>
        </div>
    </div>  

</div>

<div *ngIf="isSuscriberAuthenticated && !isMobile()" class="row cover-text" style="padding-top: 15px;">
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" *ngIf="!biblereadcurrentday.PlanStatus">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div id="titlechapter">
                <div id="carouselIndicatorsProfecy" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
                    <ol class="carousel-indicators" style="padding-bottom: 0px;">
                        <li data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" data-bs-slide-to="0" class="active"></li>
                        <span *ngFor ="let post of biblereadcurrentday.BibleReadDayChapters | slice:1; index as i;">
                            <li data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" [attr.data-bs-slide-to]="i+1"></li>
                        </span>
                    </ol>

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div *ngIf="biblereadcurrentday.BibleReadDayChapters !=null" class="mobil-item">
                                <div class="row" *ngIf="biblereadcurrentday.CurrentDay == 1" style=" padding-top: 30px; z-index: 1;">
                                    <p class="fira-sans-news-title" style="color: rgb(37,67,113);">Introducción</p>
                                    <p>Estos, pues, son los mandamientos, estatutos y decretos que el Eterno vuestro Dios mandó que os enseñase, para que los pongáis por obra… Guardando todos sus estatutos y sus mandamientos que yo te mando, tú, tu hijo, y el hijo de tu hijo, todos los días de tu vida, para que tus días sean prolongados.  (Deuteronomio 6:1-2)</p>
                                </div>
                                <div class="row" style=" z-index: 100; text-align: center; background-color:rgb(239, 244, 249); border-radius: 25px; padding: 25px;" *ngIf="!biblereadcurrentday.PlanStatus">
                                    <div class="col-4" style="z-index: 1; border-right: 3px solid red; display: flex; justify-content: flex-start; height: 75px; padding-left: 15px;">  
                                        <strong>
                                            <div style="margin-bottom: 10px; display: flex; justify-content: flex-start;">
                                                <p style="padding-left: 0px; text-align: center;  vertical-align: text-top; font-size: 25px; color: rgb(37,67,113); margin-bottom: -2.05rem;">
                                                    Día
                                                </p>
                                            </div>
                                            <p *ngIf="10 > biblereadcurrentday.CurrentDay" style="font-size: 50px; color: red;">
                                                0{{biblereadcurrentday.CurrentDay}}
                                            </p>
                                            <p *ngIf="biblereadcurrentday.CurrentDay >= 10" style="font-size: 50px; color: red;">
                                                {{biblereadcurrentday.CurrentDay}}
                                        </p>
                                        </strong>
                                    </div>
                                    <div class="col-8" style="z-index: 1; display: flex; justify-content: flex-start; align-items: left;">  
                                        <strong>
                                            <p style="text-align: left; padding-left: 15px; font-size: 15px; padding-top: 15px; color: rgb(96, 96, 96);">
                                                {{biblereadcurrentday.BibleReadDayChapters[0].Title}} <br/> 
                                                Reina Valera 1960
                                            </p>
                                        </strong>
                                    </div>
                                    <div class="vertical-class-text main-content col-12" style="margin-top: -30px; margin-left: -25px; z-index: 0;">
                                        <img src='/assets/img/Font-1.png' style="height: 100%; ">
                                    </div>
                                </div>

                                <div style="margin-top: -55px;">
                                    <p [innerHTML]= "_sanitizer.bypassSecurityTrustHtml(biblereadcurrentday.BibleReadDayChapters[0].Chapter)"></p>
                                </div>
                                <div style="display:flex; justify-content: center;">
                                    <button class="btn btn-primary btn-lg" style=" display: grid; justify-items: center; background: rgb(255, 255, 255); border: none;" (click)= "CompleteCurrentDay(biblereadcurrentday.CurrentDay,biblereadcurrentday.IdBibleReadPlan)" *ngIf="biblereadcurrentday.BibleReadDayChapters.length==1">
                                        <h2 class="CheckStyle" style="background: rgb(57, 154, 220);">
                                            <i class="bi bi-check"></i>
                                        </h2>
                                        <h2 style="color: rgb(57, 154, 220);">
                                            Completar
                                        </h2>
                                    </button>
                                </div>
                            </div>  
                        </div>

                        <div class="carousel-item" *ngFor="let post of biblereadcurrentday.BibleReadDayChapters | slice:1; let i = index">
                            <div class="mobil-item">
                                <div [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
                                    <div class="row" style="text-align: center; background-color:rgb(239, 244, 249); border-radius: 25px; padding: 25px;" *ngIf="!biblereadcurrentday.PlanStatus">
                                        <div class="col-4" style="z-index: 1; border-right: 3px solid red; display: flex; justify-content: flex-start; height: 75px; padding-left: 15px;">  
                                            <strong>
                                                <div style="margin-bottom: 10px; display: flex; justify-content: flex-start;">
                                                    <p style="padding-left: 0px; text-align: center;  vertical-align: text-top; font-size: 25px; color: rgb(37,67,113); margin-bottom: -2.05rem;">
                                                        Día
                                                    </p>
                                                </div>
                                                <p *ngIf="10 > biblereadcurrentday.CurrentDay" style="font-size: 50px; color: red;">
                                                    0{{biblereadcurrentday.CurrentDay}}
                                                </p>
                                                <p *ngIf="biblereadcurrentday.CurrentDay >= 10" style="font-size: 50px; color: red;">
                                                    {{biblereadcurrentday.CurrentDay}}
                                            </p>
                                            </strong>
                                        </div>
                                        <div class="col-8" style="z-index: 1; display: flex; justify-content: flex-start; align-items: left;">  
                                            <strong>
                                                <p style="text-align: left; padding-left: 15px; font-size: 15px; padding-top: 15px; color: rgb(96, 96, 96);">
                                                    {{post.Title}} <br/> 
                                                    Reina Valera 1960
                                                </p>
                                            </strong>
                                        </div>
                                        <div class="vertical-class-text main-content col-12" style="margin-top: -25px; margin-left: -25px; z-index: 0;">
                                            <img src='/assets/img/Font-1.png' style="height: 100%; ">
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: -50px;">
                                    <p [innerHTML]= "_sanitizer.bypassSecurityTrustHtml(post.Chapter)"></p>
                                </div>
                                <div style="display:flex; justify-content: center;">
                                    <button class="btn btn-primary btn-lg" style=" display: grid; justify-items: center; background: rgb(255, 255, 255); border: none;" (click)= "CompleteCurrentDay(biblereadcurrentday.CurrentDay,biblereadcurrentday.IdBibleReadPlan)" *ngIf="i+2==biblereadcurrentday.NoChapters">
                                        <h2 class="CheckStyle" style="background: rgb(57, 154, 220);">
                                            <i class="bi bi-check"></i>
                                        </h2>
                                        <h2 style="color: rgb(57, 154, 220);">
                                            Completar
                                        </h2>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                      <!-- Left and right controls/icons -->
                    <div *ngIf="biblereadcurrentday.NoChapters > 1" style="position: relative; bottom: 5px;  left: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="prev">
                            <span style="background-color: black; border-radius: 25px;" class="carousel-control-prev-icon"></span>&nbsp;Atras
                          </button>
                    </div>
                    <div *ngIf="biblereadcurrentday.NoChapters > 1" style="position: relative; bottom: 5px;  right: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-next" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="next">
                            Siguiente &nbsp;<span style="background-color: black; border-radius: 25px;" class="carousel-control-next-icon"></span>
                          </button>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-2" style="display: flex; align-items: center; justify-content: center;">
                    <a href="http://biblia.com/"><img src="http://api.biblia.com/v1/PoweredByBiblia.png" alt="Powered by Biblia" /></a>
                </div>
                <div class="col-sm-10" style="display: flex; align-items: center; justify-content: center; padding-left: 5px;">
                    <p  class="main-content-opacy">Texto bíblico Reina-Valera 1960® © Sociedades Bíblicas en América Latina, 1960. Derechos renovados 1988, Utilizado con permiso Reina-Valera 1960 ® es una marca registrada de las Sociedades Bíblicas
                        Unidas y puede ser usada solo bajo licencia.
                    </p>
                </div>
            </div>
        </div>
    </div> 

    <div class="row" style="text-align: center; background-color:rgb(239, 244, 249); border-radius: 25px; padding: 10px;" *ngIf="!biblereadcurrentday.PlanStatus">
        <div class="col-6" style="z-index: 1; display: flex; justify-content: flex-start; height: 75px; padding-left: 15px;">  
            <strong>
                <div style="margin-bottom: 10px; display: flex; justify-content: flex-start;">
                    <a style="text-decoration: none;" href="/bible-read-plan-progress">
                        <p style="padding-left: 0px; text-align: center;  vertical-align: text-top; font-size: 20px; color: rgb(37,67,113); margin-bottom: -2.05rem;">
                            Ver mi progreso
                        </p>
                    </a>
                </div>
            </strong>
        </div>
        <div class="col-6" style="z-index: 1; display: flex; justify-content: flex-end;">  
            <strong>
                <a style="text-decoration: none;" href="/bible-read-plan-progress">
                    <p style="font-size: 35px; margin-top: -10px; font-weight: bold; padding-right: 10px; color: rgb(37,67,113);">
                        <i class="bi bi-arrow-right"></i>
                    </p>
                </a>
            </strong>
        </div>
    </div> 

</div>

<div class="row" style="padding-top: 50px;">
</div>

<!-- The Modal 3-->
<div class="modal fade" id="myModalFin">
    <div class="modal-dialog">
    <div class="modal-content modalborders1">

        <!-- Modal Header -->
        <div class="modal-header">
            <div class="center-class">
                <img src="/assets/img/MDMCercaDeTi.png" width="45%"/>
                 
            </div>
            <div class="top-class">
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
        </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="center-class">
                    <h4 class="modal-title">Felicidades…</h4>
                </div>
                <br>
                <div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                    Usted ha completado con éxito el plan de lectura. Felicidades… Lo invitamos a que estudie la Palabra de Dios por temas, en nuestra sección: Estudios bíblicos.
                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <div class="center-class" style="padding-left: 20px; padding-right: 20px; width:100%;">
                    <button type="button" (click)= "redirect()" class="btn btn-primary" style="background: rgb(53,107,150);">
                        <strong>Estudios Bíblicos</strong>  
                    </button>
                </div>
            </div>
    </div>
    </div>
</div>
<!-- End Modal 3-->