import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MainCoverPost} from '../models/main-cover-post';
declare const $: any;
@Component({
  selector: 'app-main-review',
  templateUrl: './main-review.component.html',
  styleUrls: ['./main-review.component.css']
})
export class MainReviewComponent implements OnInit {
  mainReviewPostViewModel: MainCoverPost[];
  constructor(private api: ApiService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getMainReviewPost();
  }

  getMainReviewPost()
  {
    this.api.get("/api/mainposts/5/10").subscribe((response: any) => {
      this.mainReviewPostViewModel = response;
      }
    )
  }
  doFilter(){
    document.querySelector('.carrusel-review-container').scrollLeft += document.querySelector('.carrusel-main-review-container').scrollWidth;
  }
  doLeft(){
    document.querySelector('.carrusel-review-container').scrollLeft = document.querySelector('.carrusel-review-container').scrollLeft - document.querySelector('.carrusel-main-review-container').scrollWidth;
  }
  ImageURL(URLImage: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(URLImage);
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isSmallMobile() {
  if ($(window).width() < 450) {
      return true;
  }
  return false;
};
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};
}
