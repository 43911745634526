<div  *ngIf="!isMobile()" class="row fira-sans PaddingArti">
    <div *ngIf="!isMobile()" class="row" style="display: flex; justify-content: center; align-items: flex-end; text-align: center; padding-bottom:10px;">
        <div style="background-color: rgb(41, 67, 116); width: 65%;  border-radius: 0px 0px 15px 15px; padding-left: 15px; padding-right: 15px; padding-top: 5px; height: 42px;">
            <p class="fira-sans-strong-mobil">Plan de lectura</p>
        </div>
    </div>
</div>

<div *ngIf="isNotMobileMenu() && isMobile()" class="row cover-text">
    <div *ngIf="isNotMobileMenu() && isMobile()" class="col-sm-3">  
        <div class="row right">
            <app-sidemenu></app-sidemenu>
        </div>
    </div>
 
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div class="">
                <img class="dailyversecss" [src]='ImageURL()'>
            </div>
        </div>
    </div>   
</div>

<div  *ngIf="!isMobile()" >
    <div style="padding-top: 15px; padding-bottom: 15px;">
        <img src='/assets/img/PostList/PlandelecturaMobile.jpg' class="PlanLectura mx-auto d-block img-fluid" style="width: 100%; max-height: 250px;  border-radius: 25px; opacity: 0.65;">
    </div>
    <div style="text-align: center;">
        <p style="font-size: 110%;"><strong>¿Por qué es importante estudiar la Biblia?</strong></p>
        <p style="font-size: 90%;">¡En Su Palabra insiparada, Dios nos da las respuestas a estas preguntas y a muchas otras de gran importancia para usted!</p>
    </div>
</div>

<div class="row" *ngIf="isNotMobileMenu()" style="padding-top: 30px;">
</div>

<div *ngIf="!isSuscriberAuthenticated" class="row cover-text" style="background-color: rgb(245, 245, 245); text-align: center; padding-top: 15px; padding-bottom: 20px;">
    <label><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
</div>

<div *ngIf="isSuscriberAuthenticated && isMobile()" class="row cover-text" style="background-color: rgb(245, 245, 245);">
    <div [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" style="padding: 20px;">
        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" style="padding-left: 10px;" ng-if="isNotMobileMenu()">Progreso de mi Lectura</label>
    </div>
    <div id="titulo contenedor" [ngClass]="{'col-sm-4':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" style="padding: 20px; position: relative; display: inline-block; text-align: center;">
            <img src='/assets/img/ActualDay.png' class="imagenwidth">
            <div class="texto">
                {{biblereadcurrentday.CurrentDay}}
            </div>
    </div>

    <div class="col-sm-12" style="padding: 20px;">
        <div id="carouselIndicatorsProgress" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
            <ol class="carousel-indicators" style="padding-bottom: 0px;">
                <li data-bs-target="#carouselIndicatorsProgress" (click)="subirtitulo()" data-bs-slide-to="0" class="active"></li>
                <span *ngFor ="let post of BiblereadplangridbypageDTO.Rows | slice:1; index as i;">
                    <li data-bs-target="#carouselIndicatorsProgress" (click)="subirtitulo()" [attr.data-bs-slide-to]="i+1"></li>
                </span>
            </ol>
    
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="mobil-item">
                        <div class="col-sm-12" style="padding: 20px;">
                            <div class="row">
                                <div [ngClass]="{'col-2':!isNotPhone(),'col-4':isNotPhone()}" *ngFor="let post of BiblereadplangridbypageDTO.Rows[0].items; index as i;">
                                    <div class="row">
                                        <div style="width:100%; height: 100%;">
                                            <div style="padding: 10px;">
                                                <button *ngIf="post.Completed" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%; height: 94px; background-color: white;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div style="display: flex; justify-content: center; align-items: center;" *ngIf="!isPhone()" [ngClass]="{'col-12':isNotPhone(),'col-6':!isNotPhone()}">
                                                            <h2 class="CheckStyle" style="background: rgb(34, 192, 34);"><i class="bi bi-check"></i></h2>
                                                        </div>
                                                        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column" class="col-6">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h5 class="CheckStyle" style="color: black;">{{post.Day}}</h5>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="post.NextDayEnabled" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%; height: 94px; background-color: white;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div class="col-6" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h5 class="CheckStyle" style="color: black;">{{post.Day}}</h5>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="!post.Completed && !post.NextDayEnabled" disabled class="btn btn-primary" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%; height: 94px; background-color: white;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div class="col-6" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h3 class="CheckStyle" style="color: black;">{{post.Day}}</h3>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                         
                </div>
    
                <div class="carousel-item" *ngFor="let row of BiblereadplangridbypageDTO.Rows | slice:1; let i = index">
                    <div class="mobil-item">
                        <div class="col-sm-12" style="padding: 20px;">
                            <div class="row">
                                <div [ngClass]="{'col-2':!isNotPhone(),'col-4':isNotPhone()}" *ngFor="let post of row.items">
                                    <div class="row">
                                        <div style="width:100%; height: 100%;">
                                            <div style="padding: 15px;">
                                                <button *ngIf="post.Completed" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" style="width: 100%; height: 94px; background-color: white; border: none;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div style="display: flex; justify-content: center; align-items: center;" [ngClass]="{'col-12':isNotPhone(),'col-6':!isNotPhone()}">
                                                            <h2 class="CheckStyle" style="background: rgb(34, 192, 34);"><i class="bi bi-check"></i></h2>
                                                        </div>
                                                        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;" class="col-6">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h3 class="CheckStyle" style="color: black;">{{post.Day}}</h3>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="post.NextDayEnabled" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%; height: 94px; background-color: white;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div class="col-6" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h3 class="CheckStyle" style="color: black;">{{post.Day}}</h3>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button  *ngIf="!post.Completed && !post.NextDayEnabled" disabled class="btn btn-primary" style="width: 100%; height: 94px; background-color: white; border: none;">
                                                    <div style="display: flex; justify-content: center;" class="row">
                                                        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;" class="col-6">
                                                            <h4 style="color: black;">Día</h4> 
                                                            <h3 class="CheckStyle"  style="color: black;">{{post.Day}}</h3>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <br>
                <br>
              <!-- Left and right controls/icons -->
            <div  style="position: relative; bottom: 5px;  left: 0;">
                <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subirtitulo()" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicatorsProgress" data-bs-slide="prev">
                    <span  style="background-color: black; border-radius: 25px;" class="carousel-control-prev-icon"></span>&nbsp;Atras
                 </button>
            </div>
            <div *ngIf="!isPhone()" style="position: relative; bottom: 5px;  right: 0;">
                <button *ngIf="isNotMobileMenu()"  style="color: black;" (click)= "subirtitulo()" class="carousel-control-next" type="button" data-bs-target="#carouselIndicatorsProgress" data-bs-slide="next">
                    Siguiente &nbsp;<span style="background-color: black; border-radius: 25px;" class="carousel-control-next-icon"></span>
                  </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSuscriberAuthenticated && !isMobile()" class="row cover-text" style="background-color: rgb(245, 245, 245);">
    <div class="col-sm-12" style="height: 50px;border-radius: 15px; background-color: rgb(41, 67, 116); display: flex; align-items: center;">
        <div class="row" style="width: 100%;">
            <div class="col-3" style="display: flex; justify-content: center; margin-top: -15px;">
                <img src='/assets/img/ActualDay1.png' class="imagenwidth">
            </div>
            <div class="col-5" style="margin-top: 1%;">
                <div class="col-4" class="textoMobil">
                    Mi progreso
                </div>
            </div>
            <div class="col-4" style="text-align: end; padding-right: 15px; margin-top: 1%;">
                <div class="col-4" class="textoMobil">
                    {{biblereadcurrentday.CurrentDay}} <span style="color: red;letter-spacing: 5px;">/</span>365
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-12" style="padding: 5px;">
        <div id="carouselIndicatorsProgress" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
            <ol class="carousel-indicators" style="padding-bottom: 0px;">
                <li data-bs-target="#carouselIndicatorsProgress" (click)="subirtitulo()" data-bs-slide-to="0" class="active"></li>
                <span *ngFor ="let post of BiblereadplangridbypageDTO.Rows | slice:1; index as i;">
                    <li data-bs-target="#carouselIndicatorsProgress" (click)="subirtitulo()" [attr.data-bs-slide-to]="i+1"></li>
                </span>
            </ol>
    
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="mobil-item">
                        <div class="col-sm-12" style="padding: 5px;">
                            <div class="parent">
                                <!-- <div [ngClass]="{'col-2':!isNotPhone(),'col-2':isNotPhone()}" *ngFor="let post of BiblereadplangridbypageDTO.Rows[0].items; index as i;"> -->
                                <div *ngFor="let post of BiblereadplangridbypageDTO.Rows[0].items; index as i;">
                                    <div class="row">
                                        <div style="width:100%; height: 100%;">
                                            <div style="padding: 3px;">
                                                <button *ngIf="post.Completed" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div style="display: flex; justify-content: center; align-items: center; padding-right: 15px;" class="col-6">
                                                            <h4 class="CheckStyle" style="background: rgb(34, 192, 34);"><i class="bi bi-check"></i></h4>
                                                        </div>
                                                        <div style="display: flex; justify-content: center;" class="col-6"> 
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="post.NextDayEnabled" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center; align-items: center;">
                                                        <div class="col-12" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="!post.Completed && !post.NextDayEnabled" disabled class="btn btn-primary" [ngClass]="{'CurrentD1': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center; align-items: center;">
                                                        <div class="col-12" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                         
                </div>
    
                <div class="carousel-item" *ngFor="let row of BiblereadplangridbypageDTO.Rows | slice:1; let i = index">
                    <div class="mobil-item">
                        <div class="col-sm-12" style="padding: 5px;">
                            <div class="parent">
                                <!-- <div [ngClass]="{'col-2':!isNotPhone(),'col-3':isNotPhone()}" *ngFor="let post of row.items"> -->
                                    <div *ngFor="let post of row.items">
                                    <div class="row">
                                        <div style="width:100%; height: 100%;">
                                            <div style="padding: 5px;">
                                                <button *ngIf="post.Completed" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center;">
                                                        <div style="display: flex; justify-content: center; align-items: center; padding-right: 15px;" class="col-6">
                                                        <!-- <div style="display: flex; justify-content: center; align-items: center;" [ngClass]="{'col-5':isNotPhone(),'col-6':!isNotPhone()}"> -->
                                                            <h4 class="CheckStyle" style="background: rgb(34, 192, 34);"><i class="bi bi-check"></i></h4>
                                                        </div>
                                                        <div style="display: flex; justify-content: center;" class="col-6"> 
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="post.NextDayEnabled" class="btn btn-primary" (click)= "getBiblereadDay(post.Day)" [ngClass]="{'CurrentD': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center; align-items: center;">
                                                        <div class="col-12" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <button *ngIf="!post.Completed && !post.NextDayEnabled" disabled class="btn btn-primary" [ngClass]="{'CurrentD1': !post.CurrentDay,'CurrentDBorder':post.CurrentDay}" style="width: 100%;">
                                                    <div class="row" style="display: flex; justify-content: center; align-items: center;">
                                                        <div class="col-12" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                                                            <p class="CheckStyle"  [ngClass]="{'CurrentDay': !post.CurrentDay,'NOTCurrentDay':post.CurrentDay}">
                                                                <strong>Día</strong> <br>
                                                                <strong>{{post.Day}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <br>
                <br>
              <!-- Left and right controls/icons -->
            <div  style="position: relative; bottom: 5px;  left: 0;">
                <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subirtitulo()" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicatorsProgress" data-bs-slide="prev">
                    <span  style="background-color: black; border-radius: 25px;" class="carousel-control-prev-icon"></span>&nbsp;Atras
                 </button>
            </div>
            <div *ngIf="!isPhone()" style="position: relative; bottom: 5px;  right: 0;">
                <button *ngIf="isNotMobileMenu()"  style="color: black;" (click)= "subirtitulo()" class="carousel-control-next" type="button" data-bs-target="#carouselIndicatorsProgress" data-bs-slide="next">
                    Siguiente &nbsp;<span style="background-color: black; border-radius: 25px;" class="carousel-control-next-icon"></span>
                  </button>
            </div>
        </div>
    </div>
</div>

<div class="row cover-text" *ngIf="biblereadday.CurrentDay!=null">
    <div class="row" style="padding-top: 25px;">
    </div>
    <div [ngClass]="{'col-sm-3':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="row right" style="text-align: center;">
            <strong>
                <div style="  display: flex; align-items: center; margin: 0 auto; width: 75px; height: 10px; margin-top: 0.95rem; border-top: 3px solid red;">
                    <p style="padding-left: 5px; text-align: center;  vertical-align: text-top; font-size: 40px; color: rgb(53,107,150); margin-bottom: -2.05rem;">
                        Día
                    </p>
                </div>
                <p *ngIf="10 > biblereadday.CurrentDay" style="font-size: 95px; color: red; text-align: center; padding-left: 50px;">
                     0{{biblereadday.CurrentDay}}
                </p>
                <p *ngIf="biblereadday.CurrentDay >= 10" style="font-size: 95px; color: red; text-align: center; padding-left: 50px;">
                    {{biblereadday.CurrentDay}}
               </p>
            </strong>
        </div>
    </div>
 
    <div [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}">  
        <div class="vertical-class-text" style="padding-top: 0px; padding-bottom: 0px; padding-left: 5px; padding-right: 0px;">
            <div id="titlechapter">
                <div id="carouselIndicatorsProfecy" class="carousel slide" data-bs-interval="false" data-bs-ride="carousel">
                    <ol class="carousel-indicators" style="padding-bottom: 0px;">
                        <span  *ngFor ="let post of biblereadday.BibleReadDayChapters; index as i;">
                            <li *ngIf="i == 0" data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" [attr.data-bs-slide-to]="i" class="active"></li>
                            <li *ngIf="i > 0" data-bs-target="#carouselIndicatorsProfecy" (click)= "subir()" [attr.data-bs-slide-to]="i"></li>
                        </span>
                    </ol>

                    <div class="carousel-inner">
                        <div >

                            <div class="carousel-item" *ngFor="let day of biblereadday.BibleReadDayChapters; let i = index" [ngClass]="{'active':i==0}"> 
                                <div class="mobil-item">
                                    <div class="row" *ngIf="biblereadday.CurrentDay == 1 && i==0" style="padding-top: 30px;">
                                        <p class="fira-sans-news-title" style="color: rgb(53,107,150);">Introducción</p>
                                        <p>Estos, pues, son los mandamientos, estatutos y decretos que el Eterno vuestro Dios mandó que os enseñase, para que los pongáis por obra… Guardando todos sus estatutos y sus mandamientos que yo te mando, tú, tu hijo, y el hijo de tu hijo, todos los días de tu vida, para que tus días sean prolongados.  (Deuteronomio 6:1-2)</p>
                                    </div>
    
                                    <div class="row" style="background-color: white; z-index: 1; position:relative;">
                                         <p class="fira-sans-news-title" style="color: rgb(53,107,150);">{{day.Title}}</p>
                                    </div>
                                    <div style="margin-top: -55px;">
                                        <p [innerHTML]= "_sanitizer.bypassSecurityTrustHtml(day.Chapter)"></p>
                                    </div>
                                    <button class="btn btn-primary btn-lg BuscarColor" (click)="CompleteDay(biblereadday.CurrentDay)" *ngIf="i+1==biblereadday.BibleReadDayChapters.length">{{ActionButtonText}}</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                      <!-- Left and right controls/icons -->
                    <div  *ngIf="biblereadday.BibleReadDayChapters.length > 1" style="position: relative; bottom: 5px;  left: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="prev">
                            <span style="background-color: black;" class="carousel-control-prev-icon"></span>&nbsp;Atras
                          </button>
                    </div>
                    <div *ngIf="biblereadday.BibleReadDayChapters.length > 1" style="position: relative; bottom: 5px;  right: 0;">
                        <button *ngIf="isNotMobileMenu()" style="color: black;" (click)= "subir()" class="carousel-control-next" type="button" data-bs-target="#carouselIndicatorsProfecy" data-bs-slide="next">
                            Siguiente &nbsp;<span style="background-color: black;" class="carousel-control-next-icon"></span>
                          </button>
                    </div>
                </div>
                <br>
                <br>
                <div class="row">
                    <div class="col-sm-2" style="display: flex; align-items: center; justify-content: center;">
                        <a href="http://biblia.com/"><img src="http://api.biblia.com/v1/PoweredByBiblia.png" alt="Powered by Biblia" /></a>
                    </div>
                    <div class="col-sm-10" style="display: flex; align-items: center; justify-content: center; padding-left: 5px;">
                        <p  class="main-content-opacy">Texto bíblico Reina-Valera 1960® © Sociedades Bíblicas en América Latina, 1960. Derechos renovados 1988, Utilizado con permiso Reina-Valera 1960 ® es una marca registrada de las Sociedades Bíblicas
                            Unidas y puede ser usada solo bajo licencia.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</div>

<div class="row" style="padding-top: 50px;">
</div>

<!-- The Modal 3-->
<div class="modal fade" id="myModalFin">
    <div class="modal-dialog">
    <div class="modal-content modalborders1">

        <!-- Modal Header -->
        <div class="modal-header">
            <div class="center-class">
                <img src="/assets/img/MDMCercaDeTi.png" width="45%"/>
                 
            </div>
            <div class="top-class">
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
        </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="center-class">
                    <h4 class="modal-title">Felicidades</h4>
                </div>
                <br>
                <div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                    Usted ha completado con éxito el plan de lectura. Felicidades… Lo invitamos a que estudie la Palabra de Dios por temas, en nuestra sección: Estudios bíblicos.
                </div>
                <!--<div style="padding-right: 25px; padding-left: 25px; padding-bottom: 25px;">
                {{configurationViewModel.Value}}
                </div>-->
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <!--<button type="button" class="btn btn-primary mb-2 contact-button-style buttonborders" (click)="login()">Ingresar</button>-->
                <div class="center-class" style="padding-left: 20px; padding-right: 20px; width:100%;">
                    <button type="button" (click)= "redirect()" class="btn btn-primary" style="background: rgb(53,107,150);">
                        <strong>Estudios Bíblicos</strong>  
                    </button>
                </div>
            </div>
    </div>
    </div>
</div>
<!-- End Modal 3-->
