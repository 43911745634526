<div class="main-news-container">
    <div class="row header-news-container">
        <table *ngIf="!isNotMobileMenu() && isSmallMobile()"> 
            <tr>
                <td>
                    <div class="header-news-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Preguntas</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Preguntas" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <table *ngIf="!isNotMobileMenu() && !isSmallMobile()"> 
            <tr>
                <td>
                    <div class="header-news-container-margin">
                        <label class="vertical-line-red" [ngClass]="{'fira-sans strong':isNotMobileMenu(),'fira-sans-mobil-strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Preguntas y Respuestas</label>
                    </div>
                </td>
                <td>
                    <div class="look-more">
                        <a href="/posts?PostType=Preguntas" >
                            <label class="fira-sans-blue"><i class="bi bi-caret-right-fill"></i>Ver Mas</label>
                        </a>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row" *ngIf="isNotMobileMenu()">
            <div class="col-sm-5 col-xs-5 header-news-container-margin">
                <label class="vertical-line-red" style="padding-right: 15px;" [ngClass]="{'fira-sans-news-strong':isNotMobileMenu(),'fira-sans-news-mobil strong':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">&nbsp;Preguntas y Respuestas</label>
            </div>
            <div class="col-sm-2 col-xs-2 look-more">
                <a class="pointer" href="/posts?PostType=Preguntas">
                    <label class="fira-sans-blue"><i class="bi bi-caret-right-fill pointer"></i>Ver Mas</label>
                </a>
            </div>
        </div>
    </div>


    <div *ngIf="isNotMobileMenu()" class="carrusel-main-question-container">
        <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
        <div class="carrusel-question-container">
            <div class="carrusel">
                <div [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu()}" *ngFor="let post of mainQuestionPostViewModel">
                    <a href="/post/{{post.IdPost}}">
                        <img class="imagen" [src]="post.URLImage">
                    </a>
                        <p class="fira-sans-news-title">{{post.Title}}</p>
                        <p>{{post.Review}}</p>
                        <div class="row alieancion">
                            <div class="col-sm-12">
                                <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
    </div>

    <div *ngIf="!isNotMobileMenu()" id="carouselIndicatorsQuestions" class="carousel slide" data-ride="carousel">
        
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div *ngIf="mainQuestionPostViewModel !=null" class="mobil-item">
                    <a href="/post/{{mainQuestionPostViewModel[0].IdPost}}">
                        <img class="imagen"  [src]="mainQuestionPostViewModel[0].URLImage">
                    </a>
                    <p class="fira-sans-news-title">{{mainQuestionPostViewModel[0].Title}}</p>
                    <p>{{mainQuestionPostViewModel[0].Review}}</p>
                    <div class="row alieancion">
                        <div class="col-sm-12">
                            <a *ngIf="mainQuestionPostViewModel[0].Content" style="padding-right: 0px;" class="fira-sans-news vertical-class left" href="/post/{{mainQuestionPostViewModel[0].IdPost}}">Seguir leyendo</a>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="carousel-item" *ngFor="let post of mainQuestionPostViewModel | slice:1; let i = index">
                <div class="mobil-item">
                    <a href="/post/{{post.IdPost}}">
                        <img class="imagen"  [src]="post.URLImage">
                    </a>
                    <p class="fira-sans-news-title">{{post.Title}}</p>
                    <p>{{post.Review}}</p>
                    <div class="row alieancion">
                        <div class="col-sm-12">
                            <a *ngIf="post.Content" style="padding-right: 0px;" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                        </div>
                    </div>
                </div>
                  
            </div>
          
        </div>
        <ol class="carousel-indicators">
            <li data-bs-target="#carouselIndicatorsQuestions" data-bs-slide-to="0" class="active"></li>
            <span *ngFor ="let post of mainQuestionPostViewModel | slice:1; index as i;">
                <li data-bs-target="#carouselIndicatorsQuestions"   [attr.data-bs-slide-to]="i+1"></li>
            </span>
           
        </ol>
    </div>
</div>
