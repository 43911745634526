import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopnavbarComponent } from './components/topnavbar/topnavbar.component';
import { MainCoverComponent } from './main-cover/main-cover.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { MainProfecyComponent } from './main-profecy/main-profecy.component';
import { MainNewFocusComponent } from './main-new-focus/main-new-focus.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainPostComponent } from './main-post/main-post.component';
import { MainPostListComponent } from './main-post-list/main-post-list.component';
import { MainReviewComponent } from './main-review/main-review.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginViewModelComponent } from './models/login-view-model/login-view-model.component';
import { LoginComponent } from './login/login.component';
import { MainQuestionComponent } from './main-question/main-question.component';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { ContactusComponent } from './contactus/contactus.component';
import { BibleStudyComponent } from './bible-study/bible-study.component';
import { BibleStudyReadComponent } from './bible-study-read/bible-study-read.component';
import { BibleStudyListComponent } from './bible-study-list/bible-study-list.component';
import { FavoritePostListComponent } from './favorite-post-list/favorite-post-list.component';
import { ReadLaterPostListComponent } from './read-later-post-list/read-later-post-list.component';
import { FavoriteBibleStudyListComponent } from './favorite-bible-study-list/favorite-bible-study-list.component';
import { ReadLaterBibleStudyListComponent } from './read-later-bible-study-list/read-later-bible-study-list.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { ReadLaterListComponent } from './read-later-list/read-later-list.component';
import { BibleSearchComponent } from './bible/bible-search/bible-search.component';
import { BiblereadplanComponent } from './biblereadplan/biblereadplan/biblereadplan.component';
import { BiblereadplanprogressComponent } from './biblereadplan/biblereadplanprogress/biblereadplanprogress.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { LowerCaseUrlSerializer } from './lowercase-url-serializer';
import { UrlSerializer } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainShortsComponent } from './main-shorts/main-shorts.component';
@NgModule({
  declarations: [
    AppComponent,
    TopnavbarComponent,
    MainCoverComponent,
    SidemenuComponent,
    MainProfecyComponent,
    MainNewFocusComponent,
    FooterComponent,
    MainPostComponent,
    MainPostListComponent,
    MainReviewComponent,
    LoginViewModelComponent,
    LoginComponent,
    MainQuestionComponent,
    ContactusComponent,
    BibleStudyComponent,
    BibleStudyReadComponent,
    BibleStudyListComponent,
    FavoritePostListComponent,
    ReadLaterPostListComponent,
    FavoriteBibleStudyListComponent,
    ReadLaterBibleStudyListComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FavoriteListComponent,
    ReadLaterListComponent,
    BibleSearchComponent,
    BiblereadplanComponent,
    BiblereadplanprogressComponent,
    PrivacyNoticeComponent,
    PageNotFoundComponent,
    MainShortsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
