import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { BelievesQuestionsViewModel} from '../models/believes-questions-view-model';
import { ContactUsViewModel} from '../models/contact-us-view-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryViewModel} from '../models/country-view-model';
import { EstateCountry} from '../models/estate-country';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  CountryViewModel: CountryViewModel[];
  EstateCountry: EstateCountry[];
  dataViewModel: BelievesQuestionsViewModel[];
  totalItems: number = 0;
  formContact: UntypedFormGroup;
  firstItem: number = 0;
  itemsArray: Array<number> = [0];
  showContact = false;
  constructor(private api: ApiService, private fb: UntypedFormBuilder) {
    this.formContact = this.fb.group({
      Name: ['', Validators.required],
      Email: ['', Validators.required],
      Message: ['', Validators.required],
      Country: [CountryViewModel, Validators.required],
      IdState: ['', Validators.required]
    });
   }

  ngOnInit(): void {
    this.getData();
    this.getCountries();
  }
  getData()
  {
    this.api.get("/Believes").subscribe((response: any) => {
      this.dataViewModel = response;
      this.totalItems = this.dataViewModel.length;
      }
    )
  }
  nextItem()
  {
    var maxheight=parseInt(document.getElementById("Animacion").style.maxHeight.replace('px', ''),10);
    maxheight+=240;
    document.getElementById("Animacion").style.maxHeight=maxheight+"px";

    if(this.firstItem<this.totalItems)
    {
      this.firstItem = this.firstItem+1; 
      this.itemsArray.push(this.firstItem);
      console.log(this.firstItem)
    }
    if(this.firstItem==this.totalItems)
    {
      this.showContact = true;
    }
  }
showItem(i:number) {
    if (this.itemsArray.some(x=> x ===i)) {
        return true;
    }
    return false;
};
  contact()
  {
    if (this.formContact.invalid) {
      return;
    }
    var contactViewModel = this.formContact.value as ContactUsViewModel;
    contactViewModel.IdCountry = this.formContact.value.Country.IdCountry;
    
    this.api.post("/api/contactus",contactViewModel).subscribe(
      (response) => {
        window.location.reload();
      },
      (error) => {
        console.log(error
        );
        this.formContact = this.fb.group({
          Name: [''],
          Email: [''],
          Message: [''],
          IdCountry: [''],
          IdState: ['']
        });
      });
  }
  getCountries()
  {
    this.api.get("/api/countries").subscribe((response: any) => {
      this.CountryViewModel = response
      }
    )
  }
  getStates(IdCountry: number)
  {
    if (IdCountry) {
      this.api.get("/api/States/"+IdCountry).subscribe((response: any) => {
        this.EstateCountry = response;
      });
    }
  }
}
