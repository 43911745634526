import { Component, OnInit } from '@angular/core';
import { MainCoverPost} from '../models/main-cover-post';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../shared/api.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';
declare const $: any;
@Component({
  selector: 'app-main-post',
  templateUrl: './main-post.component.html',
  styleUrls: ['./main-post.component.css']
})
export class MainPostComponent implements OnInit {
  mainPostViewModel: MainCoverPost = new MainCoverPost();
  fullLoad:boolean=false;
  isSuscriberAuthenticated : boolean = false;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  constructor(private apiService: ApiService, private activateroute:ActivatedRoute,private router : Router,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.mainPostViewModel.IdPost = this.activateroute.snapshot.params.PostId ? Number(this.activateroute.snapshot.params.PostId) : 0;
    this.isSuscriberAuthenticated = this.apiService.isAuthenticated();
    this.getVideoValue();
    this.cargarpost();

  }

  cargarpost()
  {
    if(this.mainPostViewModel.IdPost >0)
    {
      if(this.isSuscriberAuthenticated)
      {
        this.apiService.getWithAuthentication("/api/post/"+this.mainPostViewModel.IdPost).subscribe(
          (response: any) => {
            this.fullLoad=true;
            this.mainPostViewModel = response;
            if(this.mainPostViewModel.YTCensored == false)
            {
              this.mainPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.mainPostViewModel.URLVideo:this.mainPostViewModel.URLVideoBackUp);
            }
            else if (this.mainPostViewModel.YTCensored == true)
            {
              this.mainPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.mainPostViewModel.URLVideo);
            }
          }
        );
      }
      else
      {
          this.apiService.get("/api/post/"+this.mainPostViewModel.IdPost).subscribe(
            (response: any) => {
              this.fullLoad=true;
              this.mainPostViewModel = response;
              if(this.mainPostViewModel.YTCensored == false)
              {
                this.mainPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.mainPostViewModel.URLVideo:this.mainPostViewModel.URLVideoBackUp);
              }
              else if (this.mainPostViewModel.YTCensored == true)
              {
                this.mainPostViewModel.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.mainPostViewModel.URLVideo);
              }
            }
          );
      }
    }
  }
  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.mainPostViewModel.URLImage);
  }
  getMainVideo()
  {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.mainPostViewModel.URLVideo);
  }
  
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};
getVideoValue()
{
  this.apiService.get("/Configuration?key=Video").subscribe((response: any) => {
    this.configurationViewModel = response
    }
  )
}

AddDeleteFavoritePost(IdPost:number)
{
  var autenticad=this.apiService.isAuthenticated();
  if(autenticad == false)
  {
    $('#myModal').modal('toggle')
  }
  else
  {
    this.apiService.postWithAuthentication("/api/favoritepost/"+IdPost,IdPost).subscribe(
      (response) => {
        this.cargarpost();
      },
      (error) => {
        console.log(error);
      });
    }
}
AddDeleteReadLaterPost(IdPost:number)
{
  var autenticad=this.apiService.isAuthenticated();
  if(autenticad == false)
  {
    $('#myModal').modal('toggle')
  }
  else
  {
    this.apiService.postWithAuthentication("/api/readlaterpost/"+IdPost,IdPost).subscribe(
      (response) => {
        this.cargarpost();
      },
      (error) => {
        console.log(error);
      });
  }
}
}
