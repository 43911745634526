import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { TagViewModel} from '../models/tag-view-model';
import { BibleStudyViewModel} from '../models/bible-study-view-model';
import { DomSanitizer } from '@angular/platform-browser';
declare const $: any;
@Component({
  selector: 'app-bible-study',
  templateUrl: './bible-study.component.html',
  styleUrls: ['./bible-study.component.css']
})
export class BibleStudyComponent implements OnInit {
  tagViewModel: TagViewModel[];
  biblestudyViewModel: BibleStudyViewModel = new BibleStudyViewModel();
  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) { }
  SliderContainerWidth: number = 200;
  numerOfRecords: number = 1;
  showCarrusel: boolean = false;

  ngAfterViewInit() {
    this.SliderContainerWidth = (this.numerOfRecords*42);
    this.cdRef.detectChanges(); 
  }

  ngOnInit(): void {
    this.getTags();
    this.getMainBibleStudy();
  }

  getTags()
  {
    this.api.get("/Tag/GetTags").subscribe((response: any) => {
      this.tagViewModel = response;
      this.showCarrusel = true;
      this.numerOfRecords = response.length;
      this.ngAfterViewInit()
      }
    )
  }

  getMainBibleStudy()
  {
    this.api.get("/api/latestbiblestudy").subscribe((response: any) => {
      this.biblestudyViewModel = response
      }
    )
  }
  doFilter(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth-80;
  }
  doLeft(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth+80;
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isSmallMobile() {
  if ($(window).width() < 450) {
      return true;
  }
  return false;
};
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};
}
