import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Dailyverses} from '../../models/dailyverses';
import { Biblereadcurrentday} from '../../models/biblereadcurrentday';
import { BiblereadcurrentdayPost} from '../../models/biblereadcurrentday-post';



declare const $: any;
@Component({
  selector: 'app-biblereadplan',
  templateUrl: './biblereadplan.component.html',
  styleUrls: ['./biblereadplan.component.css']
})
export class BiblereadplanComponent implements OnInit {
  response : any=[]
  dailyverse : Dailyverses = new Dailyverses();
  biblereadcurrentday : Biblereadcurrentday = new Biblereadcurrentday();
  BiblereadcurrentdayPost : BiblereadcurrentdayPost = new BiblereadcurrentdayPost();
  isSuscriberAuthenticated : boolean = false;
  IdUser : string;
 
  constructor(private api: ApiService,public _sanitizer: DomSanitizer) {
   }

  ngOnInit(): void {
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
    this.IdUser  = sessionStorage.getItem("currentUser");
    $(() => {
      var autenticad=this.api.isAuthenticated();
      if(autenticad == false)
      {
        $('#myModal').modal('toggle')
      }
    });

    this.getDailyVerse();

    if(this.isSuscriberAuthenticated==true)
    {
      this.getBiblereadCurrentDay();
    }
    
  }

  getDailyVerse()
  {
    this.api.get("/api/enabledailyverse").subscribe((response: any) => {
        this.dailyverse = response
      }
    )
  }

  getBiblereadCurrentDay()
  {
    this.api.getWithAuthentication("/api/biblereadcurrentday").subscribe((response: any) => {
        this.biblereadcurrentday = response
        console.log(this.biblereadcurrentday);
      }

    )
  }

  CompleteCurrentDay(Day:number, IdBibleReadPlan:number)
  {
    this.BiblereadcurrentdayPost.Day=Day;
    this.BiblereadcurrentdayPost.IdBibleReadPlan=IdBibleReadPlan;

    if(Day == 365)
    {
      $(() => {
        if(Day == 365)
        {
          $('#myModalFin').modal('toggle')
        }
      });
      this.api.postWithAuthentication("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response: any) => {
      }
      )
      setTimeout( () => { window.location.assign("/bible-studies");}, 15000);
    }
    else
    {
  
      this.api.postWithAuthentication("/api/biblereadcurrentday",this.BiblereadcurrentdayPost).subscribe((response: any) => {
        }
      )
      window.location.reload();
    }

  }

  redirect()
  {
    window.location.assign("/bible-studies");
  }

  subir()
  {
      document.getElementById('titlechapter').scrollIntoView();
  }

  ImageURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.dailyverse.URL);
  }
  URL365Banner() {
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://elmundodemananacercadeti.mediafire.com/file/wiiau0b7cxqznri/Emperadores.jpg/file');
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  };
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    
    return false;
  };
}
