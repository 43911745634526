
<div  *ngIf="load && isMobile()" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
<div class="row" *ngFor="let row of postByPage.Rows">
    <div class="col-xs-12 col-sm-10 col-md-10  col-lg-4 centrado" *ngFor="let post of row.Post | paginate: { id: 'foo',
    itemsPerPage: 12,
    currentPage: page,
    totalItems: totalItems}">
        <div class="row grid-item">
            <div style="background-color:white; width:100%; height: 100%;">
                <div class="thumbnail">
                    <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}">
                        <img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; max-height: 200px;min-height: 200px;  ">
                    </a>
                    <div *ngIf="!post.Content" class="youtube-video-container">
                        <iframe *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                        <video *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        <video *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    </div>
                </div>
                <div class="grid-item-text" >
                    <p class="fira-sans-news-title">
                        {{post.Title}}
                    </p>
                    <label>
                        {{post.Review}}
                    </label>
                </div>
                <div class="row" style="display: inline-block; height: 40px;">
                    <div class="row star-content alieancion">
                        <div class="col-8" style="padding-bottom: 0px;">
                            <div class="row" style="width: 100%; padding-bottom: 15px; padding-right: 15px; justify-content: flex-start; display: grid;" *ngIf="!post.showVideoLayout">
                                <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                            </div>
                        </div>
                        <div class="col-4" style="padding-bottom: 0px;">
                            <div class="row" style="display: flex; justify-content: center;">
                                <div class="row star-content">
                                    <div class="col-12 right-class">
                                        <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                        <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>   
<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center fira-sans">
        <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
        previousLabel="Ant"
        nextLabel="Sig"
        directionLinks="true"
        autoHide="true"
        screenReaderPaginationLabel="Pagination"
        screenReaderPageLabel="page"
        screenReaderCurrentLabel="You're on page"
                          responsive="true"
        maxSize="5"></pagination-controls>
    </ul>
</nav>
</div>

<div  *ngIf="load && !isMobile()" class="row fira-sans PaddingArti" style="background-color: rgb(245, 245, 245);">
    <div class="row" *ngFor="let row of postByPage.Rows">
        <div class="col-6" *ngFor="let post of row.Post | paginate: { id: 'foo',
        itemsPerPage: 12,
        currentPage: page,
        totalItems: totalItems}">
            <div class="row grid-item">
                <div style="background-color:white; width:100%; height: 100%; border-radius: 25px 25px 0px 0px;">
                    <div class="thumbnail">
                        <a *ngIf="post.Content" class="fira-sans-link vertical-class left" href="/post/{{post.IdPost}}">
                            <img *ngIf="!showVideoLayout" [src]="post.URLImage" style="width:100%; height: 100px;  border-radius: 15px;">
                        </a>
                        <div *ngIf="!post.Content" class="youtube-video-container">
                            <a  class="overlay" *ngIf="!showVideoLayout" href="/post/{{post.IdPost}}"></a>
                            <iframe class="videoradio" *ngIf="configurationViewModel.Value == 'YouTube' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                            <video class="videoradio" *ngIf="configurationViewModel.Value == 'MediaFire' && post.YTCensored==false" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                            <video class="videoradio" *ngIf="post.YTCensored" width="838" height="470" [src]='post.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                        </div>
                    </div>
                    <div class="grid-item-text" >
                        <p class="fira-sans-news-title">
                            {{post.Title}}
                        </p>
                    </div>
                    <div class="row" style="display: inline-block; height: 40px;">
                        <div class="row star-content alieancion">
                            <div class="col-12" style="padding-bottom: 0px;">
                                <div class="row" style="width: 100%; padding-bottom: 5px; padding-right: 0px; justify-content: flex-end; display: grid;" *ngIf="!post.showVideoLayout">
                                    <a *ngIf="post.Content" class="fira-sans-news vertical-class left" href="/post/{{post.IdPost}}">Seguir leyendo</a>
                                </div>
                            </div>
                            <div class="col-12" style="padding-bottom: 0px;">
                                <div class="row" style="width: 100%; padding-bottom: 10px; padding-right: 0px; justify-content: flex-end; display: grid;">
                                    <div class="row star-content">
                                        <div class="col-12 right-class">
                                            <button class="buttonaslink" (click)="AddDeleteFavoritePost(post.IdPost)"><i [ngClass]="post.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong">&nbsp;&nbsp;</i></button>
                                            <button class="buttonaslink" (click)="AddDeleteReadLaterPost(post.IdPost)"><i [ngClass]="post.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center fira-sans">
            <pagination-controls  id="foo" (pageChange)="handlePageChange($event)" (pageBoundsCorrection)="handlePageChange($event)"
            previousLabel="Ant"
            nextLabel="Sig"
            directionLinks="true"
            autoHide="true"
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"
            maxSize="5"></pagination-controls>
        </ul>
    </nav>
    </div>