import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MainCoverPost} from '../models/main-cover-post';
import { ConfigurationViewModel} from '../models/configuration-view-model';
declare const $: any;
@Component({
  selector: 'app-main-new-focus',
  templateUrl: './main-new-focus.component.html',
  styleUrls: ['./main-new-focus.component.css']
})

export class MainNewFocusComponent implements OnInit {
  mainFocusPostViewModel: MainCoverPost[];
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  test: SafeResourceUrl
  widthpadre: number;
  itemWidth: number = 300;
  numerOfRecords: number = 1;
  showCarrusel: boolean = false;
  showMainVideo : boolean = false;

  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if(!this.isMobile())
    {
      var width = this.myIdentifier.nativeElement.offsetWidth;
      this.widthpadre=width;
      this.itemWidth = (this.widthpadre/this.numerOfRecords);
      this.cdRef.detectChanges(); 
    }
  }

  ngOnInit(): void {
    this.getVideoValue();
    this.getMainFocusPost();
  }

  getMainFocusPost(){
    this.api.get("/api/mainposts/3/10").subscribe((response: any) => {
      this.mainFocusPostViewModel = response
      this.mainFocusPostViewModel.forEach(element => {
        if(element.YTCensored == false)
              {
                element.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? element.URLVideo:element.URLVideoBackUp);
              }
              else if (element.YTCensored == true)
              {
                element.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(element.URLVideo);
              }
      });
      this.showCarrusel = true;
      this.numerOfRecords = response.length -1;
      this.ngAfterViewInit();
      }
    )
  }

  videoURL(URLVideo: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(URLVideo);
  }

  doFilter(){
    document.querySelector('.carrusel-focus-container').scrollLeft = document.querySelector('.carrusel-focus-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth;
  }

  doLeft(){
    document.querySelector('.carrusel-focus-container').scrollLeft = document.querySelector('.carrusel-focus-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth;
  }
  
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }

  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    return false;
  }

  getVideoValue()
  {
    this.api.get("/Configuration?key=Video").subscribe((response: any) => {
      this.configurationViewModel = response
      this.showMainVideo = true;
      }
    )
  }
}
