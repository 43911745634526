import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MainCoverPost} from '../models/main-cover-post';

declare const $: any;
@Component({
  selector: 'app-main-profecy',
  templateUrl: './main-profecy.component.html',
  styleUrls: ['./main-profecy.component.css']
})
export class MainProfecyComponent implements OnInit {
  mainProfecyPostViewModel: MainCoverPost[];
  constructor(private api: ApiService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getMainProfecyPost();
  }

  getMainProfecyPost()
  {
    this.api.get("/api/mainposts/4/10").subscribe((response: any) => {
      this.mainProfecyPostViewModel = response
      }
    )
  }
  doFilter(){
    document.querySelector('.carrusel-container').scrollLeft = document.querySelector('.carrusel-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth;
  }
  doLeft(){
    document.querySelector('.carrusel-container').scrollLeft = document.querySelector('.carrusel-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth;
  }
  imageURL(imageVideo: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(imageVideo);
  }
  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
};
isMobile() {
  if ($(window).width() > 460) {
      return true;
  }
  
  return false;
};
}
