<div class="container">
  <div class="alert alert-warning grdp" role="alert" [hidden]="!visible">
    <p>
       Utilizamos cookies para mejorar su experiencia con nuestro sitio web. Para obtener más información sobre cómo este sitio web utiliza cookies e instrucciones sobre cómo deshabilitarlas, 
       haga <a href="https://www.lcg.org/legal/cookie-policy" style="color: white;">clic aquí para ver nuestra “Política de cookies”</a>. Al continuar utilizando este sitio web, usted acepta el uso de cookies por parte del sitio web.
       Pulse el botón ACEPTAR, para confirmar que ha leído y aceptado la información presentada.
    </p>
    <button type="button" class="btn btn-warning bcgrdp" (click)="onGRDP()"><strong>Aceptar</strong></button>
    </div>
  <app-topnavbar></app-topnavbar>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>