<div class="container-text" *ngIf="isMobile()">
    <div class="row cover-text">
        <div *ngIf="isNotMobileMenu()" class="col-sm-3">  
            <div class="row right">
                <app-sidemenu></app-sidemenu>
            </div>
            <div class="row">
                <div class="row right">
                    <div class="col-sm-5 fira-sans-news-blue strong" style="width: 85%;">
                        
                        <p style="margin-top: 100px;">
                            {{bibleStudyViewModel.Introduction}}
                        </p>
                        <div class="row" style="display: flex;">
                            <div class="row star-content">
                                <div class="col-sm-4 star-content-items">
                                    <button class="buttonaslink" (click)="AddDeleteFavoritePost(bibleStudyViewModel.IdBibleStudie)"><i [ngClass]="bibleStudyViewModel.IsStaredFavorite ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-star-fill strong"></i></button>
                                </div>
                                <div class="col-sm-4 star-content-items">
                                    <button class="buttonaslink" (click)="AddDeleteReadLaterPost(bibleStudyViewModel.IdBibleStudie)"><i [ngClass]="bibleStudyViewModel.IsStaredRead ? 'fira-sans-news-yellow ' : 'fira-sans-news-blue'" class="bi bi-bookmark-fill strong"></i></button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                   
                </div>
            </div>
           
            
        </div>
     
        <div class="vertical-class-text main-content" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div  *ngIf="!isSuscriberAuthenticated" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
                <label *ngIf="!isSuscriberAuthenticated"><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
            </div>
                <div class="youtube-video-container" *ngIf="bibleStudyViewModel.UrlVideo">
                    <iframe *ngIf="configurationViewModel.Value == 'YouTube' && bibleStudyViewModel.YTCensored == false" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                    <video *ngIf="configurationViewModel.Value == 'MediaFire' && bibleStudyViewModel.YTCensored == false" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    <video *ngIf="bibleStudyViewModel.YTCensored" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    <br>  
                </div>
                <div class="youtube-video-container" *ngIf="ImageURL() && !bibleStudyViewModel.UrlVideo">
                    <img *ngIf="isSuscriberAuthenticated" [src]="ImageURL()" alt="Lights" style="width:100%; max-width: 1296px; max-height: 600px;">
                </div>
                <br>  
                <p> 
                    <strong>
                        <h4>
                            {{bibleStudyViewModel.Title}}
                        </h4>
                    </strong>
                </p>

                <p class="fira-sans">
                </p>
                <div class="main-content-opacy" [innerHTML]= "bibleStudyViewModel.Content"></div>
                <div *ngIf="isSuscriberAuthenticated  && bibleStudyViewModel.TestEnabled" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
                    <label><h3><i><strong>Repasemos lo aprendido</strong></i></h3></label>
                </div>
                <div class="youtube-video-container" *ngIf="bibleStudyViewModel.TestEnabled && bibleStudyViewModel.Test">
                    <iframe [src]='bibleStudyViewModel.URLSaTest' width="100%" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; geolocation *; microphone *; camera *; midi *; encrypted-media *">
                    </iframe><script src="https://educacionparalavida.h5p.com/js/h5p-resizer.js" charset="UTF-8"></script>
                </div>
                <div style="text-align: center;">
                    <strong>
                        <h3>¿Qué tema desea estudiar ahora?</h3>
                    </strong>
                </div>
                <div *ngIf="true" class="carrusel-main-container">
                    <div class="row" style="padding-left: 40px; padding-right: 40px;width: 100%;">
                        <button role="button" id="left-row" class="carrusel-left-row" (click)="doLeft()"><i class="bi bi-caret-left-fill"></i></button>
                        <div class="carrusel-bible-container">
                            <div class="carrusel">
                                    <button  class="btn bnt-style" *ngFor="let tag of tagViewModel;" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu(), 'odditem':o}">
                                        <a href="/bible-studies/{{tag.Id}}" >
                                            <div> 
                                                <img *ngIf=tag.Icon [src]="tag.Icon" [ngClass]="{'item':isNotMobileMenu(),'mobil-item':!isNotMobileMenu(), 'odditem':o}" style="width:100%;"/>
                                            </div>
                                        </a>
                                    </button>
                            </div>
                        </div>
                        <button role="button" id="right-row" class="carrusel-right-row" (click)="doFilter()"><i class="bi bi-caret-right-fill"></i></button>
                    </div>
                </div>
        </div>
    </div>
</div>

<div class="container-text" *ngIf="!isMobile()">
    <div class="row cover-text">
        <div class="vertical-class-text" [ngClass]="{'col-sm-9':isNotMobileMenu(),'col-sm-12':!isNotMobileMenu()}" ng-if="isNotMobileMenu()">
            <div  *ngIf="!isSuscriberAuthenticated" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
                <label *ngIf="!isSuscriberAuthenticated"><h3><i><strong>Para poder acceder a este contenido es necesario que haya iniciado sesión previamente</strong></i></h3></label>
            </div>
                <div class="youtube-video-container" *ngIf="bibleStudyViewModel.UrlVideo">
                    <iframe class="corner-wrapper"  *ngIf="configurationViewModel.Value == 'YouTube' && bibleStudyViewModel.YTCensored == false" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen"></iframe> 
                    <video class="imagen"  *ngIf="configurationViewModel.Value == 'MediaFire' && bibleStudyViewModel.YTCensored == false" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    <video class="imagen"  *ngIf="bibleStudyViewModel.YTCensored" width="838" height="470" [src]='bibleStudyViewModel.URLSafeVideo' frameborder="0" allow="autoplay; fullscreen" controls controlsList="nodownload"></video>
                    <br>  
                </div>
                <div class="youtube-video-container" *ngIf="ImageURL() && !bibleStudyViewModel.UrlVideo">
                    <img class="imagen" *ngIf="isSuscriberAuthenticated" [src]="ImageURL()" alt="Lights" style="width:100%; max-width: 1296px; max-height: 600px;">
                </div>
                <br>
                <div class="main-content">
                    <p class="vertical-line-red"> 
                        <strong>
                            <h4>
                                {{bibleStudyViewModel.Title}}
                            </h4>
                        </strong>
                    </p>
                    <p class="fira-sans">
                    </p>
                    <div style="/*overflow: auto;*/" class="main-content-opacy" [innerHTML]= "bibleStudyViewModel.Content"></div>
                    <div *ngIf="isSuscriberAuthenticated  && bibleStudyViewModel.TestEnabled" class="main-content-opacy" style="text-align: center; padding-top: 15px; padding-bottom: 20px;">
                        <label><h3><i><strong>Repasemos lo aprendido</strong></i></h3></label>
                    </div>
                    <div class="youtube-video-container" *ngIf="bibleStudyViewModel.TestEnabled && bibleStudyViewModel.Test && isMobile()">
                        <iframe [src]='bibleStudyViewModel.URLSaTest' width="100%" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; geolocation *; microphone *; camera *; midi *; encrypted-media *">
                        </iframe><script src="https://educacionparalavida.h5p.com/js/h5p-resizer.js" charset="UTF-8"></script>
                    </div>
                    <div style="position: relative; width: 115%; left: -8%; height: auto;" *ngIf="bibleStudyViewModel.TestEnabled && bibleStudyViewModel.Test && !isMobile()">
                        <iframe [src]='bibleStudyViewModel.URLSaTest' width="100%" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; geolocation *; microphone *; camera *; midi *; encrypted-media *">
                        </iframe><script src="https://educacionparalavida.h5p.com/js/h5p-resizer.js" charset="UTF-8"></script>
                    </div>
                </div>  

                <div *ngIf="isSuscriberAuthenticated" style="text-align: center;">
                    <strong>
                        <h3>¿Qué tema desea estudiar ahora?</h3>
                    </strong>
                </div>
                <div *ngIf="!isMobile()">
                    <div>
                        <div class="scroll" >
                            <div class="slider-container" #myIdentifier id="containerpadre" [style.width.%] ='SliderContainerWidth'>  
                                    <div class="containerhijo" style="padding:15px 10px 0px 10px; width: 15%;" *ngFor="let tag of tagViewModel; let o = odd" >
                                        <button  class="btn bnt-style">
                                            <a href="/bible-studies/{{tag.Id}}">
                                                <div> 
                                                    <img *ngIf=tag.Icon [src]="tag.Icon" [ngClass]="{'mobil-item':!isNotMobileMenu(), 'odditem':o}" style="width:120%; height:50px" />
                                                </div>
                                            </a>
                                        </button>
                                    </div>
                                
                            </div> 
                        </div>
                    </div> 
                </div>
        </div>
    </div>
</div>

<div class="row star-footer" *ngIf="isMobile() && isSuscriberAuthenticated">
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row">
        <div class="star-footer-items">
            <i class="bi bi-star-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post">&nbsp;&nbsp;Mis Favoritos</a>
            </i>
        </div>
        <div style="padding-left: 20px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post">&nbsp;&nbsp;Ver Más Tarde</a>
            </i>
        </div>
    </div>
</div>

<div class="row star-footer1" *ngIf="!isMobile() && isSuscriberAuthenticated">
    <div class="star-footer-row-border-mobil"> 
    </div>
    <div class="col-xs-12 col-md-8 col-lg-6 star-footer-row-mobil">
        <div>
            <i class="bi bi-star-fill fira-sans-news-blue strong-mobil animation">
                <a class="animation" style="text-decoration: none;" href="/favorite-post"></a>
            </i>
        </div>
        <div style="padding-left: 10px;">
            <i class="bi bi-bookmark-fill fira-sans-news-blue strong-mobil animation">
                <a class="animation" style="text-decoration: none;" href="/read-later-post"></a>
            </i>
        </div>

    </div>
    <div style="width: 15px;">

    </div>
</div>