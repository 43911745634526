import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { TagViewModel} from '../models/tag-view-model';
import { BibleStudyViewModel} from '../models/bible-study-view-model';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';
import { BibleStudyByPageViewModel } from '../models/bible-study-by-page-view-model';
declare const $: any;
@Component({
  selector: 'app-bible-study-list',
  templateUrl: './bible-study-list.component.html',
  styleUrls: ['./bible-study-list.component.css']
})

export class BibleStudyListComponent implements OnInit {
  tagViewModel: TagViewModel[];
  bibleStudiesByPage: BibleStudyByPageViewModel;
  load : boolean = false;
  firstVideoLoad : boolean = false;
  isSuscriberAuthenticated : boolean = false;
  totalItems : number = 15;
  page : number = 1;
  tagId: number=1;
  biblestudyViewModel: BibleStudyViewModel = new BibleStudyViewModel();
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  rowItems : number = 3;
  SliderContainerWidth: number = 200;
  numerOfRecords: number = 1;
  showCarrusel: boolean = false;

  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private activateroute:ActivatedRoute, private cdRef: ChangeDetectorRef) { }
  
  ngAfterViewInit() {
    this.SliderContainerWidth = (this.numerOfRecords*42);
    this.cdRef.detectChanges(); 
  }

  ngOnInit(): void {
    this.isSuscriberAuthenticated = this.api.isAuthenticated();
    this.getVideoValue();
    this.getMainBibleStudy();
    
    if (this.activateroute.snapshot.params.IdTag>0)
    {
      this.changeTag(this.activateroute.snapshot.params.IdTag)
      this.tagId=this.activateroute.snapshot.params.IdTag;
    }
    else
    {
      this.tagId=0;
    }

    this.getBibleStudyByPage(this.tagId);

    $(() => {
      var autenticad=this.api.isAuthenticated();

      if(autenticad == false)
      {
        $('#myModal').modal('toggle')
      }
    })
            
    if(this.isSuscriberAuthenticated==true)
    {
      this.getTags();
    }
  }

  getMainBibleStudy()
  {
    this.api.get("/api/latestbiblestudy").subscribe((response: any) => {
      this.biblestudyViewModel = response
      }
    )
  }

  getTags()
  {
    this.api.get("/Tag/GetTags").subscribe((response: any) => {
      this.tagViewModel = response
      this.showCarrusel = true;
      this.numerOfRecords = response.length;
      this.ngAfterViewInit()
      }
    )
  }

  changeTag(idTag:number)
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }
    this.page=1;
    this.tagId=idTag;
    
    if(this.isSuscriberAuthenticated)
    {
        this.api.getWithAuthentication("/api/biblestudiestag/"+idTag+"/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
          this.bibleStudiesByPage = response;
          this.totalItems = this.bibleStudiesByPage.TotalRecords;
          this.load = true;
          this.bibleStudiesByPage.Rows.forEach(element => {
            element.BibleStudies.forEach(post => {
              post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.Image);
              post.URLSafeVideo= this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.UrlVideo:post.UrlVideoBk);
            })
          })
        })
    }
  }

  getBibleStudyByPage(tagId:number)
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }

    if(this.isSuscriberAuthenticated==true)
    {
      this.api.getWithAuthentication("/api/biblestudiestag/"+tagId+"/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
          this.bibleStudiesByPage = response;
          this.totalItems = this.bibleStudiesByPage.TotalRecords;
          this.load = true;
          this.bibleStudiesByPage.Rows.forEach(element => {
            element.BibleStudies.forEach(post => {
              post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.Image);
              post.URLSafeVideo= this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.UrlVideo:post.UrlVideoBk);
            })
          })
        }
      )
    }
  }

  SerchBibleStudy(name:string)
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }

    if(name.length==0)
    {
      name = " "
    }

    if(this.isSuscriberAuthenticated==true)
    {
      this.api.getWithAuthentication("/api/search/biblestudy/"+name+"/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
          this.bibleStudiesByPage = response;
          this.totalItems = this.bibleStudiesByPage.TotalRecords;
          this.load = true;
          this.bibleStudiesByPage.Rows.forEach(element => {
            element.BibleStudies.forEach(post => {
              post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.Image);
              post.URLSafeVideo= this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.UrlVideo:post.UrlVideoBk);
            })
          })
        }
      )
    }
  }

  getVideoValue()
  {
    this.api.get("/Configuration?key=Video").subscribe((response: any) => {
      this.configurationViewModel = response
    })
  }

  doFilter(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft + document.querySelector('.carrusel-main-container').scrollWidth-80;
  }

  doLeft(){
    document.querySelector('.carrusel-bible-container').scrollLeft = document.querySelector('.carrusel-bible-container').scrollLeft - document.querySelector('.carrusel-main-container').scrollWidth+80;
  }

  handlePageChange(event) {
    this.page = event;
    this.getBibleStudyByPage(this.tagId);
    window.scroll(0,0)
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
        return true;
    }
    return false;
  }
  
  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    return false;
  }

  AddDeleteFavoriteStudy(IdPost:number, IdTag:number)
  {
    var autenticad=this.api.isAuthenticated();

    if(this.tagId==0)
    {
      IdTag=0;
    }

    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/favoriteStudies/"+IdPost,IdPost).subscribe(
        () => {
          this.getBibleStudyByPage(IdTag);
        },
        (error) => {
          console.log(error);
        });
      }
  }
  AddDeleteReadLaterStudy(IdPost:number, IdTag:number)
  {
    var autenticad=this.api.isAuthenticated();
    if(this.tagId==0)
    {
      IdTag=0;
    }
    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/readlaterstudy/"+IdPost,IdPost).subscribe(
        () => {
          this.getBibleStudyByPage(IdTag);
        },
        (error) => {
          console.log(error);
        });
    }
  }
}
