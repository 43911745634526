import { SafeResourceUrl } from '@angular/platform-browser';
export class BibleStudyViewModel {
    IdBibleStudie: number;
    Title: string;
    Introduction: string;
    Image: string;
    UrlVideo: string;
    UrlVideoBk: string;
    IdTag: number;
    Enabled: boolean;
    Content: string;
    TestEnabled: boolean;
    Test: string;
    URLSafeVideo: SafeResourceUrl;
    URLSafeImage: SafeResourceUrl;
    URLSaTest: SafeResourceUrl;
    IsStaredFavorite:boolean;
    IsStaredRead:boolean;
    YTCensored:boolean;
}
