import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../shared/api.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  showMsg : boolean = false;
  form: UntypedFormGroup;
  msg : string ="";
  userName : string ="";
  constructor(private api: ApiService, private fb: UntypedFormBuilder) { this.form = this.fb.group({
    UserName: ['', Validators.required]
  });}

  ngOnInit(): void {
  }
emailInput()
{
  this.showMsg = false;
  this.msg ="";
}
forgotPassword()
{
  if (this.form.invalid) {
    return;
  }
  var user = this.form.get('UserName').value as string;
  this.api.get("/api/forgotPassword/"+user).subscribe(
    (response) => {
      this.msg ="Link enviado a " + (response as any).message; 
      if(this.msg.length > 1)
      {
        this.showMsg = true;
      }
      this.form = this.fb.group({
        UserName: ['', Validators.required]
      });
    },
    (error) => {
      console.log(error);
      this.form = this.fb.group({
        UserName: ['', Validators.required]
      });
    });
}
}
