<div class="card-body eea-notice">
    <legend>
        Aviso de privacidad
    </legend>
    <p *ngIf="eeaCountry; else nonEeaText">
        La información personal que nos proporciona por medio de este formulario o de cualquier otra manera,
        la administramos de acuerdo con nuestra <a href="https://www.lcg.org/sites/default/files/legal/pdf/Privacy_Statement_GDPR_PS_122021_Multi-Lang.pdf#Spanish">Declaración de privacidad</a>.
        Living Church of God, UK Scottish Charity (Caridad No. SC028554) y Living Church of God (International), Inc.,
        una corporación sin fines de lucro de North Carolina USA (NC SOSID: 0783290), son administradores conjuntos de
        los datos y la información que usted proporciona en este formulario web. Al enviar este formulario, confirma que
        ha leído nuestra Declaración de privacidad.
    </p>
    <ng-template #nonEeaText>
        <p>
            La información personal que nos proporciona por medio de este formulario o de cualquier otra manera,
            la administramos de acuerdo con nuestra <a href="https://www.lcg.org/sites/default/files/legal/pdf/Privacy_Statement_GDPR_PS_122021_Multi-Lang.pdf#Spanish">Declaración de privacidad</a>.
            Al enviar este formulario, confirma que ha leído nuestra Declaración de privacidad.
        </p>
    </ng-template>
</div>
