import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PostByPageViewModel} from '../models/post-by-page-view-model';
import { MainCoverPost} from '../models/main-cover-post';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationViewModel} from '../models/configuration-view-model';

declare const $: any;

@Component({
  selector: 'app-favorite-post-list',
  templateUrl: './favorite-post-list.component.html',
  styleUrls: ['./favorite-post-list.component.css']
})
export class FavoritePostListComponent implements OnInit {
  postByPage: PostByPageViewModel;
  load : boolean = false;
  firstVideoLoad : boolean = false;
  totalItems : number = 15;
  page : number = 1;
  videoId : number = 1;
  MainVideo : MainCoverPost;
  postType :string ="";
  title :string ="";
  showVideoLayout : boolean = false;
  configurationViewModel : ConfigurationViewModel = new ConfigurationViewModel();
  rowItems : number = 3;

  constructor(private api: ApiService,private _sanitizer: DomSanitizer, private activateroute:ActivatedRoute, private cdRef: ChangeDetectorRef  ) { }

  ngOnInit(): void {
    this.activateroute.queryParams.subscribe(params => {
      this.postType = params['PostType'];
      this.videoId = params['VideoId'];
    });

    this.getVideoValue();

    if(this.videoId != undefined)
    {
      this.getMainVideo();
    }
    
    $(() => {
      var autenticad=this.api.isAuthenticated();
      if(autenticad == false)
      {
        $('#myModal').modal('toggle')
      }
      else{
        this.getPostByPage();
      } 
    });
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  getMainVideo()
  {
    this.api.get("/api/post/"+this.videoId).subscribe((response: any) => {
      this.MainVideo = response;
      this.firstVideoLoad = true;
      this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.MainVideo.URLVideo:this.MainVideo.URLVideoBackUp);
    });
  }
  videoURL(URLVideo: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(URLVideo);
  }

  getPostByPage()
  {
    if(!this.isMobile())
    {
      this.rowItems = 2;
    }
    this.api.getWithAuthentication("/api/favoritepost/"+this.page+"/"+this.rowItems).subscribe((response: any) => {
      this.postByPage = response;
      this.totalItems = this.postByPage.TotalRecords;
      this.postByPage.Rows.forEach(element => {
        element.Post.forEach(post => {
          post.URLSafeImage= this._sanitizer.bypassSecurityTrustResourceUrl(post.URLImage);
          if(post.YTCensored == false)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? post.URLVideo:post.URLVideoBackUp);
            }
            else if (post.YTCensored == true)
            {
              post.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(post.URLVideo);
            }
        });
      });

      if(this.videoId ==undefined){
        this.MainVideo = this.postByPage.Rows[0].Post[0];
        if(this.MainVideo.YTCensored == false)
            {
              this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.configurationViewModel.Value == "MediaFire" ? this.MainVideo.URLVideo:this.MainVideo.URLVideoBackUp);
            }
            else if (this.MainVideo.YTCensored == true)
            {
              this.MainVideo.URLSafeVideo=this._sanitizer.bypassSecurityTrustResourceUrl(this.MainVideo.URLVideo);
            }
      }
      this.load = true;
      this.firstVideoLoad = true;
      this.cdRef.detectChanges();
    }
    )
  }

  loadPage(page: number) {
    this.page =1 ;
  }

  handlePageChange(event){
    this.page = event;
    this.load = false;
    this.getPostByPage();
    window.scroll(0,0)
  }

  isNotMobileMenu() {
    if ($(window).width() > 991) {
      return true;
    }
    return false;
  }

  isMobile() {
    if ($(window).width() > 460) {
        return true;
    }
    return false;
  }

  getVideoValue()
  {
    this.api.get("/Configuration?key=Video").subscribe((response: any) => {
      this.configurationViewModel = response
      }
    )
  }
  AddDeleteFavoritePost(IdPost:number)
  {
    var autenticad=this.api.isAuthenticated();
    
    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/favoritepost/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        });
      }
  }
  AddDeleteReadLaterPost(IdPost:number)
  {
    var autenticad=this.api.isAuthenticated();
    
    if(autenticad == false)
    {
      $('#myModal').modal('toggle')
    }
    else
    {
      this.api.postWithAuthentication("/api/readlaterpost/"+IdPost,IdPost).subscribe(
        () => {
          this.getPostByPage();
        },
        (error) => {
          console.log(error);
        });
    }
  }
}

